import dateMath from '@elastic/datemath';
import { EuiSuperDatePicker } from '@elastic/eui';
import axios from 'axios';
import { cloneDeep, sortBy } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ROOT from '../../client';
import { createMC, getMinioClient, getSearcData } from '../../services';
import {
	configureFieldStatus,
	saveAreaQuery,
	saveDate,
	saveImageKeys,
	saveImageLoadCount,
	saveMaskedFlag,
	saveSearchData,
	setDeleteRecordCount,
	setIsNoRecordsAvailable,
	setView,
} from '../../store/actions';
import { LAST_POINT_OF_FAILURE, RAW_LOGS, REDACTION_VIEW } from '../../utilities/constants';
import customTost from '../Notification';
import './dateTimePicker.scss';

const DateTimePicker = (props) => {
	const dispatch = useDispatch();
	const {
		indexValue,
		indexAlias,
		aliasFlag,
		mappingImageKey,
		screenSignature,
		configuredFields,
		observedAtDetail,
		selectedBucket,
		imageDetail,
		maskedBucket,
		view,
		buckets,
		envars,
		maskedFlag,
		isDeleteModeOn,
	} = useSelector((store) => store.storeProps);
	const {
		setFilterValue,
		setLowerRange,
		setHigherRange,
		setGraphdata,
		setQueryData,
		setSelectedOption,
		graphEvent,
		setGraphEvent,
		setIsLoading,
		isLoading,
		setEnableExport,
		queryString,
		setMinioClient,
		setMapping,
		isDisabled,
		setClearFilter,
	} = props;
	const [start, setStart] = useState('now-15d');
	const [end, setEnd] = useState('now');
	const timezone = moment.tz.guess();

	useEffect(() => {
		try {
			if (graphEvent) {
				onTimeChange(graphEvent);
			}
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line
	}, [graphEvent]);

	useEffect(() => {
		try {
			let event = {};
			event.start =
				observedAtDetail && observedAtDetail.startDate ? observedAtDetail.startDate : start;
			event.end = observedAtDetail && observedAtDetail.endDate ? observedAtDetail.endDate : end;
			event.chart = false;
			event.map = true;
			onTimeChange(event);
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line
	}, [queryString, screenSignature, selectedBucket, maskedBucket, isDeleteModeOn === false]);

	const createMinioClient = () => {
		return new Promise((resolve, reject) => {
			try {
				if (buckets.length && selectedBucket) {
					let mc1 = '';
					const bucket1Info = buckets.find((item) => item.bucketName === selectedBucket.bucketName);
					if (bucket1Info) {
						if (envars?.bucketConfigAccess) {
							axios
								.get(`${ROOT}/api/bucket-data?metaInfo=${bucket1Info.metaInfo}&secrets=true`)
								.then((res) => {
									if (res?.data?.secrets) {
										const data = JSON.parse(
											Buffer.from(res.data.secrets, 'base64').toString('ascii'),
										);
										mc1 = getMinioClient({
											endPoint: data.endpoint
												? data.endpoint
												: 'storage-fiq-53e0863f-82db-4403.fiq-dev.com',
											port: data.PORT ? Number(data.PORT) : 443,
											useSSL: data.USE_SSL ? data.USE_SSL : true,
											accessKey: data.access_key_id ? data.access_key_id : '',
											secretKey: data.secret_access_key ? data.secret_access_key : '',
										});
										if (maskedBucket) {
											const bucket2Info = buckets.find(
												(item) => item.bucketName === maskedBucket.bucketName,
											);
											if (bucket2Info) {
												axios
													.get(
														`${ROOT}/api/bucket-data?metaInfo=${bucket2Info.metaInfo}&secrets=true`,
													)
													.then((res2) => {
														if (res2?.data?.secrets) {
															const dataB2 = JSON.parse(
																Buffer.from(res2.data.secrets, 'base64').toString('ascii'),
															);
															const mc2 = getMinioClient({
																endPoint: dataB2.endpoint
																	? dataB2.endpoint
																	: 'storage-fiq-53e0863f-82db-4403.fiq-dev.com',
																port: dataB2.PORT ? Number(dataB2.PORT) : 443,
																useSSL: dataB2.USE_SSL ? dataB2.USE_SSL : true,
																accessKey: dataB2.access_key_id ? dataB2.access_key_id : '',
																secretKey: dataB2.secret_access_key ? dataB2.secret_access_key : '',
															});
															setMinioClient({ firstBucket: mc1, secondBucket: mc2 });
															resolve(true);
														}
													})
													.catch((error) => {
														reject(false);
														setIsLoading(false);
														console.error(error);
													});
											}
										} else {
											setMinioClient({ firstBucket: mc1 });
											resolve(true);
										}
									}
								})
								.catch((error) => {
									setIsLoading(false);
									reject(false);
									console.error(error);
								});
						} else {
							const mc = createMC(envars);
							setMinioClient({ firstBucket: mc, secondBucket: mc });
							resolve(true);
						}
					}
				}
			} catch (error) {
				setIsLoading(false);
				console.error(error);
			}
		});
	};

	// on query search
	const onTimeChange = (e) => {
		try {
			if (!e.map) {
				setMapping(true);
			}
			if (view === REDACTION_VIEW && maskedBucket === '') {
				dispatch(setView('carouselView'));
			} else {
				dispatch(
					setView(
						maskedBucket ? (view === REDACTION_VIEW ? REDACTION_VIEW : 'masked') : 'carouselView',
					),
				);
			}
			if (maskedFlag && maskedBucket) {
				// dispatch(setView('masked'));
				dispatch(saveMaskedFlag(false));
			}
			if (!isLoading) {
				setIsLoading(true);
			}
			let startDateVal = '';
			let endDateVal = '';
			if (e.chart) {
				startDateVal = e.start;
				endDateVal = e.end;
			} else {
				startDateVal = moment(dateMath.parse(e.start)).format('YYYY-MM-DDTHH:mm:ss');
				endDateVal = moment(
					dateMath.parse(
						e.end === 'now/d' || e.end === 'now/w' || e.end === 'now/M' || e.end === 'now/y'
							? 'now'
							: e.end,
					),
				).format(e.end === 'now-1d/d' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss');
				if (e.end === 'now-1d/d') {
					endDateVal = `${endDateVal}T23:59:59`;
				}
			}
			if (startDateVal && endDateVal) {
				startDateVal = moment.tz(startDateVal, timezone).format();
				endDateVal = moment.tz(endDateVal, timezone).format();
			}
			let elasticQuery = queryString.length ? queryString : [];
			let observedAt =
				startDateVal && endDateVal
					? {
							key: 'observed_at',
							value: `${startDateVal} AND ${endDateVal}`,
							operator: 'BETWEEN',
							operand: 'AND',
					  }
					: '';
			if (envars?.storageMechanism === 'minio') {
				if (selectedBucket) {
					createMinioClient().then((res) => {
						if (res) {
							getData(e, elasticQuery, observedAt);
						} else {
							customTost({
								type: 'error',
								message: 'Something went wrong. Please check try later.',
							});
						}
					});
				}
			} else {
				getData(e, elasticQuery, observedAt);
			}
			if (observedAtDetail) {
				if (
					observedAtDetail.startDate !== startDateVal ||
					observedAtDetail.endDate !== endDateVal
				) {
					saveObservedAt(observedAt, startDateVal, endDateVal);
				}
			} else {
				saveObservedAt(observedAt, startDateVal, endDateVal);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const saveObservedAt = (observedAt, startDate, endDate) => {
		try {
			dispatch(
				saveDate({
					dateRange: observedAt,
					startDate: startDate,
					endDate: endDate,
				}),
			);
		} catch (error) {
			console.error(error);
		}
	};

	const getData = (e, q, date) => {
		try {
			dispatch(setIsNoRecordsAvailable({ noRecordsAvailable: false }));
			setEnableExport(false);
			setGraphEvent('');
			setStart(e.start);
			setEnd(e.end);
			setQueryData([]);
			dispatch(saveSearchData([]));
			dispatch(saveAreaQuery(''));
			dispatch(saveImageKeys([]));
			const payload = {
				body: JSON.stringify({
					index: indexValue,
					alias: aliasFlag ? indexAlias : '',
					groups: q,
					date: date,
					chart: e.chart,
					mappingKey: mappingImageKey,
					indexes: ['event_logs'],
				}),
			};
			/* istanbul ignore next */
			if (isDeleteModeOn) {
				setClearFilter && setClearFilter();
				axios
					.post(`${ROOT}/api/get-log-count`, payload)
					.then((res) =>
						dispatch(
							setDeleteRecordCount({
								deleteRecordCount: res.data || 0,
							}),
						),
					)
					.catch((error) => console.error(error));
			}
			getSearcData(payload)
				.then((resp) => {
					if (resp?.data?.preloads) {
						dispatch(saveImageLoadCount(resp.data.preloads));
					}
					if (resp?.data?.data) {
						const data = resp.data.data;
						setFilterValue([]);
						setLowerRange('');
						setHigherRange('');
						setEnableExport(true);
						if (mappingImageKey === '') {
							setIsLoading(false);
							setEnableExport(false);
							customTost({
								type: 'error',
								message: 'Please select image key',
							});
							return;
						}
						if (data?.aggregations?.images_count?.buckets) {
							if (data.aggregations.images_count.buckets.length > 0) {
								const graphArray = [];
								data.aggregations.images_count.buckets.forEach((obj) => {
									const graphObj = [];
									graphObj.push(obj.key, obj.doc_count);
									graphArray.push(graphObj);
								});
								setGraphdata(graphArray);
							} else {
								setGraphdata([]);
							}
						}
						if (data?.hits?.hits) {
							if (data.hits.hits.length > 0) {
								const sourceData = [];
								data.hits.hits.forEach((element) => {
									sourceData.push(element._source);
								});
								dispatch(saveSearchData(sourceData));
								dispatch(configureFieldStatus(true));
								setQueryData(sourceData);
								let esOptionsArray = [];
								if (imageDetail.length) {
									imageDetail.forEach((m, i) => {
										if (m && m.type !== 'nested') {
											esOptionsArray.push({
												id: i++,
												value: m.label,
												isChecked: false,
												type: m.type ? m.type : '',
											});
										}
									});
								}

								esOptionsArray = sortBy(esOptionsArray, 'value');
								const optionsObj = cloneDeep(esOptionsArray);
								optionsObj.forEach((option) => {
									option.isChecked = configuredFields.some((el) => el.label === option.value)
										? true
										: option.isChecked;
								});

								const filteredOptionsObj = optionsObj.filter(
									(val) => val?.value !== RAW_LOGS && val?.value !== LAST_POINT_OF_FAILURE,
								);
								setSelectedOption(filteredOptionsObj);
								const checkFieldStatus = optionsObj.filter((val) => val.isChecked === true);
								if (checkFieldStatus?.length) {
									dispatch(configureFieldStatus(true));
								} else {
									dispatch(configureFieldStatus(false));
								}
							} else {
								if (indexValue !== '') {
									setIsLoading(false);
									setEnableExport(false);
									customTost({
										type: 'error',
										message: 'No images found from search query',
									});
								}
							}
						}
					} else {
						customTost({
							type: 'error',
							description: 'Something went wrong. Please try later.',
							duration: 10,
						});
						setIsLoading(false);
						setEnableExport(false);
					}
				})
				.catch((error) => {
					if (error?.response?.data?.message && error?.response?.data?.statusCode !== 401) {
						customTost({
							type: 'error',
							description:
								error.response.data.message === 'Cannot read property "type" of undefined'
									? 'Please make sure you have selected the correct elastic index.'
									: 'Something went wrong. Please try later.',
							duration: 10,
						});
						setIsLoading(false);
						setEnableExport(false);
					}
				});
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setEnableExport(false);
		}
	};

	return (
		<EuiSuperDatePicker
			isDisabled={isDisabled}
			onTimeChange={onTimeChange}
			start={start}
			end={end}
		/>
	);
};

DateTimePicker.propTypes = {
	setFilterValue: PropTypes.func,
	setLowerRange: PropTypes.func,
	setHigherRange: PropTypes.func,
	setGraphdata: PropTypes.func,
	setQueryData: PropTypes.func,
	setSelectedOption: PropTypes.func,
	graphEvent: PropTypes.string,
	setGraphEvent: PropTypes.func,
	setIsLoading: PropTypes.func,
	isLoading: PropTypes.bool,
	setEnableExport: PropTypes.func,
	queryString: PropTypes.array,
	setMinioClient: PropTypes.func,
	setMapping: PropTypes.func,
	isDisabled: PropTypes.bool,
	setClearFilter: PropTypes.func,
};

export default DateTimePicker;
