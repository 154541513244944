import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CreateUsers from '../CreateUsers';
import DataDeletePolicy from '../DataDeletePolicy';
import Forwarding from '../Forwarding';
import OCR from '../OCR';
import RedactionTab from './RedactionTab';

const { TabPane } = Tabs;

const SettingsTab = (props) => {
	const {
		isdeploying,
		isDisabled,
		isProxySelected,
		initialValues,
		pegEnvars,
		setPegEnvars,
		setProxySelected,
		ocrEnvars,
	} = props;
	const path = useLocation();
	let query = '';
	if (path?.search) {
		query = new URLSearchParams(path.search);
	}
	const paramValue = query ? query.get('action') : false;
	const [disableRedactionTab, setDisableRedactionTab] = useState(false);
	const [disableForwardTab, setDisableForwardTab] = useState(false);
	const { reRedactionStatus } = useSelector((store) => store.storeProps);
	const operations = (
		<div className='disableMessage'>
			{disableForwardTab
				? 'The forwarding and ocr tab is disabled until UPDATE is in progress.'
				: 'The redaction list and ocr tab is disabled until DEPLOY/RE-REDACTION is in progress.'}
		</div>
	);
	return (
		<Tabs
			defaultActiveKey={paramValue ? '3' : '1'}
			className='peg_tabs'
			tabBarExtraContent={
				disableRedactionTab || disableForwardTab ? (reRedactionStatus ? '' : operations) : ''
			}
		>
			<TabPane tab='Redact' key='1' disabled={disableRedactionTab}>
				{/* <AllowBlockList /> */}
				<RedactionTab
					setDisableForwardTab={setDisableForwardTab}
					isdeploying={isdeploying}
					isDisabled={isDisabled}
					initialValues={initialValues}
					pegEnvars={pegEnvars}
					setPegEnvars={setPegEnvars}
				/>
			</TabPane>
			<TabPane tab='Forward' key='2' disabled={disableForwardTab}>
				<Forwarding
					setDisableRedactionTab={setDisableRedactionTab}
					isDisabled={isDisabled}
					isProxySelected={isProxySelected}
					setProxySelected={setProxySelected}
					initialValues={initialValues}
					pegEnvars={pegEnvars}
					setPegEnvars={setPegEnvars}
				/>
			</TabPane>
			<TabPane tab='Delete' key='3'>
				<DataDeletePolicy />
			</TabPane>
			<TabPane tab='OCR' key='4' disabled={disableForwardTab || disableRedactionTab}>
				<OCR ocrEnvars={ocrEnvars} />
			</TabPane>
			<TabPane tab='Users' key='5'>
				<CreateUsers />
			</TabPane>
		</Tabs>
	);
};

SettingsTab.propTypes = {
	setActiveTab: PropTypes.func,
	form: PropTypes.object,
	isdeploying: PropTypes.bool,
	setIsDeploying: PropTypes.func,
	redactionLoading: PropTypes.bool,
	SetRedactionLoading: PropTypes.func,
	isDisabled: PropTypes.bool,
	isProxySelected: PropTypes.bool,
	setUseProxy: PropTypes.func,
	updateConfig: PropTypes.func,
	initialValues: PropTypes.object,
	pegEnvars: PropTypes.array,
	ocrEnvars: PropTypes.array,
	setPegEnvars: PropTypes.func,
	setProxySelected: PropTypes.func,
};

export default SettingsTab;
