import Icon from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import PropTypes from 'prop-types';
import { useState } from 'react';
import NoDataImg from '../../../Images/table-no-data.svg';
import {
	ActionBtnIcon,
	EditIcon,
	PreviousArrow,
	RightArrow,
	TrashIcon,
	ViewIcon,
} from '../../../utilities/IconSets';
import { Spinner } from '../../Loader';
import './styles.scss';

// add custom icons for table's footer
const itemRender = (_, type, originalElement) => {
	if (type === 'prev') {
		return (
			<a>
				<Icon component={PreviousArrow} />
			</a>
		);
	}
	if (type === 'next') {
		return (
			<a>
				<Icon component={RightArrow} />
			</a>
		);
	}
	return originalElement;
};

// config for table's pagination
const pagination = {
	position: ['bottomCenter'],
	itemRender,
	locale: { items_per_page: '' },
	total: 0,
	defaultPageSize: 10,
	bordered: false,
	hideOnSinglePage: true,
};

// change ant table not-found component's text and icon
const locale = {
	emptyText: (
		<div className='regex-table-nodata'>
			<img className='nodata-icon' src={NoDataImg} alt='no-data' />
			<span>Your table is currently empty. Create your first entry now.</span>
		</div>
	),
};

const RegexTable = (props) => {
	const {
		regexData,
		setEditView,
		isLoading,
		setShowRegexForm,
		setPreviewView,
		setPreviewDisable,
		handleRedactionProcess,
		setCommitDisable,
		setIsModelOpen,
		setDeleteView,
		setReplaceWordsList,
		setActionType,
		setReplaceActionType,
		setRedactionResult,
		setCurrentRegex,
		setOcr,
	} = props;
	const [showAction, setShowAction] = useState([]);

	// handle edit action, show edited row in regex form
	const handleEdit = (rowData) => {
		if (rowData?.type === 'replace') {
			setReplaceWordsList(rowData?.placeholders);
			setActionType(true);
			setReplaceActionType(true);
		}
		setShowRegexForm(true);
		setEditView({ state: true, data: rowData });
		setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
		setPreviewDisable(true);
		setCommitDisable(true);
		handleRedactionProcess('');
		setRedactionResult([]);
		setCurrentRegex('');
		setOcr([]);
	};

	// handle delete action, remove the item from regex data
	const handleDelete = (delObj) => {
		setDeleteView({ state: true, data: delObj });
		setIsModelOpen(true);
		setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
		handleRedactionProcess('');
		setRedactionResult([]);
		setCurrentRegex('');
		setOcr([]);
	};

	return (
		<div className='regex-table'>
			<Table
				className='table'
				defaultPageSize={10}
				locale={locale}
				dataSource={regexData}
				bordered
				loading={{
					indicator: <Spinner />,
					spinning: isLoading,
				}}
				pagination={pagination}
			>
				<Column
					title='Description'
					key='description'
					dataIndex='description'
					ellipsis={true}
					render={(value) => (
						<Tooltip placement='topLeft' title={value}>
							<div className='content-ellipsis'>{value}</div>
						</Tooltip>
					)}
				/>
				<Column
					title='Scope'
					key='scopeQueryString'
					dataIndex='scopeQueryString'
					ellipsis={true}
					render={(value) => (
						<Tooltip placement='topLeft' title={value}>
							<div className='content-ellipsis'>{value || 'Global'}</div>
						</Tooltip>
					)}
				/>
				<Column
					title='Action'
					key='action'
					// dataIndex='type'
					render={(record) => (
						<div className='action-icon-box default-cursor'>
							<div className='action-wrap'>
								{record.type === 'replace' ? (
									<>Replace-User-Input</>
								) : record.type === 'replace_system' ? (
									<>Replace-System-Generated</>
								) : record.type === 'pass' ? (
									<>
										{/* <CirclePass className='action-icon-g' /> */}
										Allow
									</>
								) : (
									<>
										{/* <CircleClose className='action-icon-r' /> */}
										Block
									</>
								)}
							</div>
						</div>
					)}
				/>
				<Column
					key='extra'
					dataIndex=''
					width={20}
					className='extra-options'
					render={(e, record) => (
						<div
							className={`action-icon-box actionIcons`}
							onMouseEnter={() => setShowAction(record)}
							onMouseLeave={() => setShowAction([])}
						>
							<Icon component={ActionBtnIcon} />
							<div
								className={`action-icon-box-abs no-border ${
									record !== showAction ? 'hid' : 'vis'
								} `}
							>
								<Tooltip placement='top' title='Preview'>
									<Icon
										className='view-icon'
										component={ViewIcon}
										onClick={() => {
											setPreviewView({
												enable: true,
												state: true,
												data: record,
												singlePreview: true,
											});
											setOcr([]);
											setRedactionResult([]);
										}}
									/>
								</Tooltip>
								<Tooltip placement='top' title='Edit'>
									<Icon
										className='edit-icon'
										component={EditIcon}
										onClick={() => handleEdit(record)}
									/>
								</Tooltip>
								<Tooltip placement='top' title='Delete'>
									<Icon
										className='delIcon'
										component={TrashIcon}
										onClick={() => handleDelete(record)}
									/>
								</Tooltip>
							</div>
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

RegexTable.propTypes = {
	regexData: PropTypes.array,
	form: PropTypes.object,
	setEditView: PropTypes.func,
	isLoading: PropTypes.bool,
	setShowRegexForm: PropTypes.func,
	setPreviewView: PropTypes.func,
	setCommitDisable: PropTypes.func,
	setPreviewDisable: PropTypes.func,
	handleRedactionProcess: PropTypes.func,
	setIsModelOpen: PropTypes.func,
	setDeleteView: PropTypes.func,
	setReplaceWordsList: PropTypes.func,
	setActionType: PropTypes.func,
	setReplaceActionType: PropTypes.func,
	setRedactionResult: PropTypes.func,
	setCurrentRegex: PropTypes.func,
	setOcr: PropTypes.func,
};

export default RegexTable;
