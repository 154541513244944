/* eslint-disable */

import { CloseOutlined, DeleteOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Radio, Select, Tooltip, notification } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { REDACTION_API, createMC } from '../../../services';
import AddScope from '../../AddScope';
import CustomButton from '../../Button';
import { Spinner } from '../../Loader';
import customTost from '../../Notification';
import UploadList from '../../UploadList';
import { getElasticQuery } from '../../utilities/queryBuilder';
import RedactionModal from './redactionModal';
import './styles.scss';

const { Option } = Select;

// get signed url from minio bucket and return singned url
export const getSignedUrl = async (envars, imageName, bucket = 'forwarded') => {
	try {
		const mc = createMC(envars);

		const promise = () => {
			return new Promise(function (resolve) {
				/* istanbul ignore next */
				const url = mc.presignedGetObject(bucket, imageName);
				url
					.then((resp) => {
						resolve({
							screenshotKey: imageName,
							url: resp,
							error: false,
						});
					})
					.catch(() => {
						resolve({
							screenshotKey: imageName,
							url: null,
							error: true,
						});
					});
			});
		};

		const url = await promise();
		if (url.error) {
			customTost({
				type: 'error',
				message: 'Please check your first bucket configuration.',
			});
		}
		return url;
	} catch (err) {
		return '';
	}
};

// download all the editor and other fileds data in json format
/* istanbul ignore next */
export const downloadJson = ({ obj }) => {
	try {
		const exportName = 'redaction'; // (Math.random() + 1).toString(36).substring(7)

		const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj));
		const downloadAnchorNode = document.createElement('a');
		downloadAnchorNode.setAttribute('href', dataStr);
		downloadAnchorNode.setAttribute('download', exportName + '.json');
		document.body.appendChild(downloadAnchorNode); // required for firefox
		downloadAnchorNode.click();
		downloadAnchorNode.remove();
	} catch (error) {
		console.log(error);
	}
};

let cancelApiToken;

const Redaction = (props) => {
	const {
		setPreviewLoading,
		getSelectedImg,
		handleRedactionProcess,
		isLocLoading,
		setLocLoading,
		currentRegex,
		setRedactionSettings,
		redactionSettings,
		renderBoundingBox,
		setOcr,
		setRedactionResult,
		initialValues,
		setPreviewView,
		ocrData,
		commitDisable,
		setCommitDisable,
		formSubmit,
		setFormSubmit,
	} = props;
	const [form] = Form.useForm();
	const { adminUserFlag, envars, mappingProps } = useSelector((store) => store.storeProps);
	const mc = createMC(envars);
	const defaultPassList = 'pass-list.txt';
	const defaultBlockList = 'block-list.txt';
	const [pass, setPass] = useState({ addList: [], removeList: [] });
	const [block, setBlock] = useState({ addList: [], removeList: [] });
	const [isModalOpen, setModelOpen] = useState(false);
	const [isRedactRady, setRedactRady] = useState(false);
	const [isRedactFormEnabel, setRedactForm] = useState(false);
	const [isUploading, setIsuploading] = useState({ Pass: false, Block: false });
	const [dictionaryFile, setDictionaryFile] = useState('');
	const downloadFile = useRef(null);
	const [selectedList, setSelectedList] = useState(defaultPassList);
	const [selectedWordAction, setSelectedWordAction] = useState('Pass');
	const [searchWordsList, setSearchWordsList] = useState([]);
	const [formRowData, setFormRowData] = useState([
		{
			key: 0,
			field: '',
			fieldType: '',
			operand: '',
			operandOptions: [],
		},
	]);
	const [filterGroups, setFilterGroups] = useState([]);
	const [scopeQueryString, setScopeQueryString] = useState('');
	const [initialScopeData, setInitialScopeData] = useState([]);
	const [previewDisable, setPreviewDisable] = useState(false);
	const [allWords, setAllWords] = useState([]);
	const [searchLoading, setSearchLoading] = useState(false);
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const [typedWord, setTypedWord] = useState('');
	const [newWordList, setNewWordList] = useState([]);
	const [duplicateWord, setDuplicateWord] = useState('');
	const [listOptions, setListOptions] = useState([
		{
			value: defaultPassList,
			label: 'Default Allow List',
		},
		{
			value: defaultBlockList,
			label: 'Default Block List',
		},
	]);
	const [formValues, setFormValues] = useState('');
	const [elasticQuery, setElasticQuery] = useState('');
	const [fileDetails, setFileDetails] = useState([
		{
			redaction_type: 'Pass',
			filename: defaultPassList,
			label: 'Default Allow List',
			file_status: true,
		},
		{
			redaction_type: 'Block',
			filename: defaultBlockList,
			label: 'Default Block List',
			file_status: true,
		},
	]);
	const [commitStatus, setCommitStatus] = useState(false);
	const [scopeValidation, setScopeValidation] = useState(false);
	const [disableAction, setDisableAction] = useState(false);
	const [searchValidation, setSearchValidation] = useState(false);
	const [deleteFileStatus, setDeleteFileStatus] = useState(false);
	const [clickedDeleteList, setClickedDeleteList] = useState({ state: false, selectedList: '' });
	const [fileNameValidation, setFileNameValidation] = useState({ state: false, message: '' });
	const [requiredNewWords, setRequiredNewWords] = useState(false);
	const [deleteWordArray, setDeleteWordArray] = useState([]);
	const [addButtonState, setAddButtonState] = useState(true);

	const streamData = (fileName) => {
		return new Promise((resolve, reject) => {
			const chunks = [];
			/* istanbul ignore next */
			mc.getObject('dictionaries', fileName, function (err, dataStream) {
				if (err) {
					return console.error(err);
				}
				dataStream.on('data', function (chunk) {
					chunks.push(Buffer.from(chunk));
				});
				dataStream.on('end', function () {
					let data = Buffer.concat(chunks).toString('utf-8');
					resolve(data);
				});
				dataStream.on('error', function (err) {
					reject(err?.message || '');
				});
			});
		});
	};

	const saveWordFile = async () => {
		/* istanbul ignore next */
		mc.statObject('dictionaries', 'words.json')
			.then(async (res) => {
				if (res?.lastModified) {
					const data = await streamData('words.json');
					const wordsFileObj = JSON.parse(data);
					let listOpts = [];
					wordsFileObj.forEach((val) => {
						if (val.file_status) {
							listOpts.push({
								value: val.filename,
								label: val.label,
							});
						}
					});
					setListOptions(listOpts);
					setFileDetails(wordsFileObj);
				}
			})
			.catch((err) => {
				if (err.code === 'NotFound') {
					mc.putObject('dictionaries', 'words.json', JSON.stringify(fileDetails), function (err) {
						if (err) {
							customTost({
								type: 'error',
								message: 'Something went wrong.',
							});
						}
					});
				}
			});
	};

	useEffect(() => {
		saveWordFile();
	}, []);

	useEffect(() => {
		setPreviewDisable(ocrData?.length ? false : true);
	}, [ocrData]);

	const resetStates = () => {
		setSearchValidation(false);
		setRequiredNewWords(false);
		setElasticQuery('');
		setScopeValidation(false);
		setTypedWord('');
		setSearchWordsList([]);
		setNewWordList([]);
		setDeleteWordArray([]);
	};

	const clearAction = () => {
		resetStates();
		setCommitStatus(false);
		form.setFieldsValue({
			listName: defaultPassList,
			type: 'Pass',
			newListName: '',
		});
		if (selectedList == defaultPassList) {
			getFileData();
		}
		setSelectedList(defaultPassList);
		setSelectedWordAction('Pass');
		setCommitDisable(true);
		saveWordFile();
		resetScopeStates();
		setDisableAction(false);
		handleRedactionProcess('');
		setRedactionResult([]);
		setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
	};

	useEffect(() => {
		if (form?.setFieldsValue && initialValues.length) {
			const cleaning = initialValues.find((i) => i.name === 'cleaning');
			const symspell = initialValues.find((i) => i.name === 'symspell__edit_distance');
			form.setFieldsValue({
				// set defautl values in form if not present in res
				cleaning: cleaning?.value || 'false',
				symspell__edit_distance: symspell?.value || '0',
			});
		}
	}, [initialValues]);

	/* istanbul ignore next */
	const generateElasticQuery = () => {
		const query = getElasticQuery(filterGroups, mappingProps);
		setElasticQuery(query);
	};

	/* istanbul ignore next */
	useEffect(() => {
		if (filterGroups.length) {
			generateElasticQuery();
			setScopeValidation(false);
		}
	}, [filterGroups]);

	const handleFormValueChange = () => {
		setCommitDisable(true);
	};

	const handleSubmit = async (values) => {
		try {
			setFormSubmit('');
			if (!ocrData.length) {
				notification.warning({
					message: 'Preview is unavailable as the OCR of this image does not exist.',
				});
				return;
			}
			if (
				!scopeQueryString &&
				selectedList !== defaultPassList &&
				selectedList !== defaultBlockList
			) {
				setScopeValidation(true);
				return;
			}
			/* istanbul ignore next */
			if (selectedList == 'New') {
				if (fileNameValidation.state) {
					return;
				}
				if (newWordList.length == 0) {
					setRequiredNewWords(true);
					return;
				}
			}

			setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
			if (values?.cleaning && values?.symspell__edit_distance) {
				// get current image id from carousel
				const imgId = getSelectedImg();
				const imgKey = imgId.split('.');

				let parms = {
					filename: values.listName == 'New' ? values.newListName : values.listName,
					redaction_type: values.type,
					add_words: newWordList,
					remove_words: deleteWordArray,
					scope_query: elasticQuery,
					scope_data: formRowData,
					scope_query_string: scopeQueryString,
					download_file: values.listName == 'New' ? false : true,
					UUID: imgKey[0],
					cleaning: JSON.parse(values.cleaning),
					symspell__edit_distance: JSON.parse(values.symspell__edit_distance),
					custom_regex: currentRegex ? [currentRegex] : [],
					edit_regex: '',
				};

				// cancel API if there are to many requests
				if (typeof cancelApiToken !== typeof undefined) {
					await cancelApiToken.cancel('Operation canceled due to new request.');
				}
				cancelApiToken = axios.CancelToken.source();
				setPreviewLoading(true);
				setLocLoading(true);
				const res = await REDACTION_API(parms, {
					cancelToken: cancelApiToken.token,
				});
				const { data } = res;
				if (data.statusCode === 200) {
					setFormValues(parms);
					const maskedImg = await getSignedUrl(envars, `${imgKey[0]}_redacted.${imgKey[1]}`);
					handleRedactionProcess(maskedImg);
					setRedactRady(true);
					setCommitDisable(false);
					/* istanbul ignore next */
					setTimeout(() => {
						renderBoundingBox(res?.data?.redacted_ocr);
						setOcr(res?.data?.redacted_ocr);
						customTost({
							type: 'success',
							message: data?.message ? data.message : 'Redacted Successfully!.',
						});
						setPreviewLoading(false);
						setLocLoading(false);
					}, 2000);
					setPreviewView({ enable: true, state: false, data: '', singlePreview: false });
				} else {
					setPreviewLoading(false);
					setLocLoading(false);
					setCommitDisable(true);
					customTost({
						type: 'error',
						message: data?.message ? data.message : 'Internal server error.',
					});
					handleRedactionProcess('');
				}
			}
		} catch (err) {
			setCommitDisable(true);
			if (err.message !== 'Operation canceled due to new request.') {
				setPreviewLoading(false);
				setLocLoading(false);
				handleRedactionProcess('');

				customTost({
					type: 'error',
					message: err?.response?.data?.message || 'Internal server error.',
				});
			}
		}
	};

	useEffect(() => {
		if (formSubmit === 'submit') {
			form.submit();
		}
	}, [formSubmit]);

	// export/download json file in users system
	const exportSettings = (e) => {
		try {
			e.stopPropagation();
			const obj = {
				pass: pass,
				block: block,
				cleaning: form.getFieldValue('cleaning'),
				symspell__edit_distance: form.getFieldValue('symspell__edit_distance'),
				// isPass: form.getFieldValue('isPass'),
			};
			downloadJson({ obj });

			customTost({
				type: 'success',
				message: 'Settings exported successfully!',
			});
		} catch (err) {
			customTost({
				type: 'error',
				message: 'Something went wrong',
			});
		}
	};

	// import json file and display data in text editor and other fiels
	const importSettings = (event) => {
		try {
			const filetType = event.target.files[0].type;

			if (filetType !== 'application/json') {
				customTost({
					type: 'error',
					message: 'Invalid file. It should be a valid JSON.',
				});
				return;
			}
			const fileReader = new FileReader();
			fileReader.readAsText(event.target.files[0], 'UTF-8');

			fileReader.onload = (e) => {
				const result = e.target.result ? JSON.parse(e.target.result) : {};

				if (result.pass && result.block) {
					setPass({
						addList: result.pass?.addList || [],
						removeList: result.pass?.removeList || [],
					});
					setBlock({
						addList: result.block?.addList || [],
						removeList: result.block?.removeList || [],
					});
					form?.setFieldsValue({
						cleaning: result?.cleaning || false,
						symspell__edit_distance: result?.symspell__edit_distance || '0',
						// isPass: result?.isPass || false,
					});
					customTost({
						type: 'success',
						message: 'Settings imported successfully!',
					});
					return;
				}
				customTost({
					type: 'error',
					message: "Invalid 'data' field. 'data' should be a valid JSON.",
				});
			};
		} catch (err) {
			customTost({
				type: 'error',
				message: 'Something went wrong',
			});
		}
	};

	const onSettingsChange = (e) => {
		let settings = e.symspell__edit_distance
			? { ...redactionSettings, symspell__edit_distance: JSON.parse(e.symspell__edit_distance) }
			: { ...redactionSettings, cleaning: JSON.parse(e.cleaning) };
		setRedactionSettings(settings);
	};

	const handleWordsActionChange = (e) => {
		setSelectedWordAction(e?.target?.value);
		setNewWordList([]);
		setTypedWord('');
		setSearchWordsList([]);
		if (selectedList !== 'New') {
			const listNameVal = e?.target?.value == 'Pass' ? defaultPassList : defaultBlockList;
			setSelectedList(listNameVal);
			form.setFieldsValue({ listName: listNameVal });
		}
	};

	const resetScopeStates = () => {
		setInitialScopeData([]);
		setScopeQueryString('');
		setFormRowData([
			{
				key: 0,
				field: '',
				fieldType: '',
				operand: '',
				operandOptions: [],
			},
		]);
	};

	const handleFileAction = (e) => {
		resetStates();
		setFileNameValidation({
			state: false,
			message: '',
		});
		const action = e !== defaultPassList ? (e.indexOf('allow') !== -1 ? 'Pass' : 'Block') : 'Pass';
		const actionTypeVal = e == 'New' ? 'Pass' : action;
		setDisableAction(e == defaultPassList || e == defaultBlockList || e == 'New' ? false : true);
		setSelectedWordAction(actionTypeVal);
		form.setFieldsValue({ type: actionTypeVal, newListName: '' });
		setSelectedList(e);
	};

	// Fetch Allow/Block List file from minio
	const getDictionaryFile = () => {
		try {
			const fileName = selectedList;
			/* istanbul ignore next */
			// fetch a text file from minio dictrionarie and save in state
			mc.statObject('dictionaries', fileName)
				.then((metaData) => {
					if (metaData) {
						mc.presignedGetObject('dictionaries', fileName, 604800, {
							'response-content-disposition': 'attachment',
						})
							.then((res) => {
								if (res) {
									setDictionaryFile(res);
								}
							})
							.catch((error) => {
								console.log('error: ', error);
							});
					}
				})
				.catch((err) => {
					console.log('err: ', err);
					customTost({
						type: 'error',
						message:
							err.code === 'NotFound'
								? 'List File does not exist yet.'
								: 'Something went wrong. Please try after sometime.',
					});
				});
		} catch (err) {
			console.error(err);
		}
	};

	/* istanbul ignore next */
	const getFileData = async () => {
		try {
			setSearchLoading(true);
			const fileName = selectedList;
			const fileData = await streamData(fileName);
			if (fileData) {
				const wordsArray = fileData.split('1\n');
				const modifiedArray = wordsArray.map((val) => {
					if (val.indexOf(' 1') !== -1) {
						return val.replace(' 1', '');
					} else {
						return val.replace(' ', '');
					}
				});
				setAllWords(modifiedArray);
			} else {
				setAllWords([]);
			}
			setSearchLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	/* istanbul ignore next */
	const setScopeData = (selectedList) => {
		const findListDetail = fileDetails.find((val) => val.filename == selectedList);
		if (findListDetail?.scope_query_string) {
			const scopeData = findListDetail.scope_data;
			setScopeQueryString(findListDetail.scope_query_string);
			setElasticQuery(findListDetail?.scope_query);
			let formInitialValues = [];
			if (scopeData?.length) {
				setFormRowData(findListDetail.scope_data);
				formInitialValues = scopeData.map((val) => {
					return {
						field: val.field,
						operand: val.operand,
						value: val.value,
						operator: val.operator == 'AND' ? true : false,
					};
				});
			}
			setInitialScopeData(formInitialValues);
		}
	};

	useEffect(() => {
		if (isFileUploaded) {
			getFileData();
			setIsFileUploaded(false);
		}
	}, [isFileUploaded]);

	useEffect(() => {
		resetScopeStates();
		if (selectedList !== 'New') {
			getDictionaryFile();
			setDictionaryFile();
			getFileData();
			setScopeData(selectedList);
		} else {
			setAllWords([]);
		}
	}, [selectedList]);

	const handleSearchWord = (e) => {
		setCommitDisable(true);
		const word = e?.target?.value;
		if (word.indexOf(' ') !== -1) {
			setSearchValidation(true);
			return;
		}
		setTypedWord(word);
		setSearchValidation(false);
		const isTypedWordExist = newWordList.find((val) => val.toLowerCase() === word.toLowerCase());
		if (isTypedWordExist) {
			setDuplicateWord(isTypedWordExist);
			setPreviewDisable(true);
			return;
		}
		setDuplicateWord('');
		if (allWords.length && word.length > 1) {
			const foundWords = allWords.filter((val) => val.toLowerCase().startsWith(word.toLowerCase()));
			setSearchWordsList(foundWords);
			const isSameWordExist = foundWords.find((w) => w.toLowerCase() == word.toLowerCase());
			setAddButtonState(isSameWordExist ? true : false);
		} else {
			setAddButtonState(false);
			setSearchWordsList([]);
		}
		if (previewDisable) {
			setPreviewDisable(false);
		}
	};

	const handleAddWord = () => {
		setNewWordList([...newWordList, typedWord]);
		setTypedWord('');
		setRequiredNewWords(false);
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13 && typedWord.length > 1) {
			e.preventDefault();
			const addBtnState = typedWord ? (duplicateWord ? true : addButtonState) : true;
			if (!duplicateWord && !addBtnState) {
				handleAddWord();
			}
		}
	};

	const handleDeleteAddedWord = (w) => {
		setCommitDisable(true);
		if (newWordList.length == 1) {
			setNewWordList([]);
			setDuplicateWord('');
			return;
		}
		if (duplicateWord == w) {
			setDuplicateWord('');
		}
		const updatedArray = newWordList.filter((val) => val !== w);
		if (updatedArray.length) {
			setNewWordList(updatedArray);
		}
	};

	const handleRemoveDeletedWord = (w) => {
		setCommitDisable(true);
		if (deleteWordArray.length == 1) {
			setDeleteWordArray([]);
			return;
		}
		const updatedArray = deleteWordArray.filter((val) => val !== w);
		if (updatedArray.length) {
			setDeleteWordArray(updatedArray);
		}
	};

	const handleDeleteWord = (w) => {
		setCommitDisable(true);
		if (deleteWordArray.length) {
			const isExist = deleteWordArray.find((val) => val == w);
			if (isExist) {
				return;
			}
		}
		setDeleteWordArray([...deleteWordArray, w]);
	};

	useEffect(() => {
		if (selectedList == 'New') {
			const typedFileName = form.getFieldValue('newListName');
			handleFileNameChange({
				target: {
					value: typedFileName,
				},
			});
		}
	}, [selectedWordAction]);

	const handleFileNameChange = (e) => {
		const text = e?.target?.value;
		if (text) {
			if (text?.indexOf(' ') !== -1) {
				setFileNameValidation({
					state: true,
					message: 'Space not allowed.',
				});
			} else {
				const isFileNameMatched = fileDetails.find(
					(val) =>
						val.original_filename == text &&
						val.redaction_type == selectedWordAction &&
						val.file_status,
				);
				setFileNameValidation({
					state: isFileNameMatched ? true : false,
					message: isFileNameMatched ? 'File name is already exist.' : '',
				});
			}
		} else {
			setFileNameValidation({
				state: false,
				message: '',
			});
		}
	};

	useEffect(() => {
		if (commitStatus) {
			clearAction();
		}
	}, [commitStatus]);

	return (
		<>
			<input
				type='file'
				name='file'
				accept='.json'
				id='file-input'
				onChange={importSettings}
				onClick={(event) => (event.target.value = null)}
			/>
			<div className='readaction-wrapper'>
				<div className='import-btns' style={{ display: 'none' }}>
					<Tooltip placement='top' title='Import Settings'>
						<div>
							<CustomButton
								className='gray-btn imp-button'
								htmlFor='file-input'
								onClick={(e) => {
									e.stopPropagation();
									document.getElementById('file-input')?.click();
								}}
							>
								{/* {<UploadOutlined />} */}
								<img src='./import_icon.png' alt='Import image icon' />
							</CustomButton>
						</div>
					</Tooltip>
					<Tooltip placement='top' title='Export Settings'>
						<div>
							<CustomButton className='gray-btn exp' onClick={exportSettings}>
								{/* {<DownloadOutlined />} */}
								<img src='./export_icon.png' alt='Export image icon' />
							</CustomButton>
						</div>
					</Tooltip>
				</div>
				<div className='wordsForm'>
					<Form
						className='form-real'
						form={form}
						layout='vertical'
						initialValues={{
							listName: defaultPassList,
							type: 'Pass',
							symspell__edit_distance: '0',
							cleaning: 'false',
						}}
						onFinish={handleSubmit}
						onValuesChange={handleFormValueChange}
						requiredMark={false}
					>
						<div className='readaction-body'>
							<div>
								<div className='scopeItem'>
									{selectedList == defaultPassList || selectedList == defaultBlockList ? (
										<label>Scope applied: Global</label>
									) : (
										<>
											<AddScope
												setFormRowData={setFormRowData}
												formRowData={formRowData}
												setFilterGroups={setFilterGroups}
												setScopeQueryString={setScopeQueryString}
												scopeQueryString={scopeQueryString}
												initialScopeData={initialScopeData}
												setInitialScopeData={setInitialScopeData}
											/>
											{scopeValidation && (
												<p style={{ color: 'red', marginTop: '-10px' }}>
													Please add scope before preview.
												</p>
											)}
										</>
									)}
								</div>
								<div className='listNameField'>
									{selectedList !== 'New' && (
										<div className='uploadSection'>
											{selectedList == defaultPassList || selectedList == defaultBlockList ? (
												<>
													{adminUserFlag && (
														<UploadList
															source={selectedWordAction}
															fileName={selectedList}
															setIsuploading={setIsuploading}
															isUploading={isUploading}
															downloadFile={downloadFile}
															setIsFileUploaded={setIsFileUploaded}
															getDictionaryFile={getDictionaryFile}
														/>
													)}
												</>
											) : (
												<>
													{deleteFileStatus ? (
														<Spinner className='download-spinner' />
													) : (
														<Tooltip placement='top' title={`Delete selected list`}>
															<Button
																onClick={() => {
																	setModelOpen(true);
																	setClickedDeleteList({ state: true, selectedList });
																}}
																className='deleteListBtn'
																icon={<DeleteOutlined />}
															/>
														</Tooltip>
													)}
												</>
											)}

											{dictionaryFile ? (
												<>
													<Tooltip placement='top' title={`Download list`}>
														<a
															download
															className='uploaded_file'
															ref={downloadFile}
															href={dictionaryFile}
														>
															{/* <DownloadOutlined className='file_icon' /> */}
															<img src='./download.png' alt='Download list icon' />
														</a>
													</Tooltip>
												</>
											) : (
												<Spinner className='download-spinner' />
											)}
										</div>
									)}
									<Form.Item label='List Name' name='listName'>
										<Select id='wordsFile' onSelect={handleFileAction} showSearch>
											<Option key={'newList'} value={'New'}>
												Create New List
											</Option>
											{listOptions.length > 0 && (
												<>
													{listOptions.map((val, i) => {
														return (
															<Option key={i} value={val?.value}>
																{val?.label}
															</Option>
														);
													})}
												</>
											)}
										</Select>
									</Form.Item>
								</div>

								{selectedList == 'New' && (
									<>
										<Form.Item
											label='Enter New List Name'
											name={'newListName'}
											className='wordActionItem'
											onChange={handleFileNameChange}
											rules={[
												{
													required: true,
													message: 'File name is required.',
												},
											]}
										>
											<Input className={fileNameValidation.state ? 'error' : ''} />
										</Form.Item>
										{fileNameValidation.state && (
											<p style={{ color: 'red', marginTop: '-8px' }}>
												{fileNameValidation.message}
											</p>
										)}
									</>
								)}
								<Form.Item label='Action' name='type' className='wordActionItem'>
									<Radio.Group className='checkbox-wrapper' onChange={handleWordsActionChange}>
										<Radio disabled={disableAction} value={'Pass'}>
											Allow
										</Radio>
										<Radio disabled={disableAction} value={'Block'}>
											Block
										</Radio>
									</Radio.Group>
								</Form.Item>

								<div className='searchWords'>
									<label>
										Search & Update Word List
										<Tooltip
											overlayClassName='wordSearchTooltip'
											title='Search for "words" below AND add or remove from wordlist.'
										>
											<InfoCircleFilled />
										</Tooltip>
									</label>
									<div className='inputWordSection'>
										<Input
											value={typedWord}
											disabled={searchLoading}
											className='inputWord'
											onChange={handleSearchWord}
											placeholder={searchLoading ? 'Loading words list...' : ''}
											onKeyDown={handleKeyDown}
										/>
										<Button
											type='primary'
											className='bat-primary addWord'
											disabled={
												typedWord.length > 1 ? (duplicateWord ? true : addButtonState) : true
											}
											onClick={handleAddWord}
										>
											Add Word
										</Button>
									</div>
									{searchValidation && <p style={{ color: 'red' }}>Space not allowed.</p>}
									{requiredNewWords && <p style={{ color: 'red' }}>Please add words.</p>}
									{newWordList.length > 0 && (
										<div className='newWords'>
											<ul>
												{newWordList.map((nw) => {
													return (
														<li key={nw} className={duplicateWord == nw ? 'duplicateWord' : ''}>
															<p>{nw}</p>
															<CloseOutlined
																id='delWord'
																onClick={() => handleDeleteAddedWord(nw)}
															/>
														</li>
													);
												})}
											</ul>
										</div>
									)}

									{deleteWordArray.length > 0 && (
										<div className='newWords deletedWordsList'>
											<h3>Selected words to delete</h3>
											<ul>
												{deleteWordArray.map((dw) => {
													return (
														<li key={dw}>
															<p>{dw}</p>
															<CloseOutlined onClick={() => handleRemoveDeletedWord(dw)} />
														</li>
													);
												})}
											</ul>
										</div>
									)}

									{searchWordsList.length > 0 ? (
										<div className='wordsTable'>
											<ul>
												{searchWordsList.map((w) => {
													return (
														<li key={w}>
															<p>{w}</p>
															<DeleteOutlined onClick={() => handleDeleteWord(w)} />
														</li>
													);
												})}
											</ul>
										</div>
									) : (
										<>
											{typedWord && (
												<p className='notFoundMessage'>
													{typedWord.length > 1
														? 'Not found!'
														: 'Please enter atleast two characters to search.'}
												</p>
											)}
										</>
									)}
								</div>
							</div>
							<div className='list-container dual-container'>
								<div className='dual-row'>
									<Form.Item
										className='form-field'
										label={<>Fuzzy Matching</>}
										tooltip={{
											title: 'Disable exact match',
											icon: <InfoCircleFilled />,
										}}
										name='symspell__edit_distance'
										rules={[{ required: true, message: 'Required' }]}
									>
										<Select
											className='fuzzy'
											onChange={(e) => onSettingsChange({ symspell__edit_distance: e })}
										>
											<Option value='1'>true</Option>
											<Option value='0'>false</Option>
										</Select>
									</Form.Item>
									<Form.Item
										className='form-field'
										label={<>Cleaning</>}
										tooltip={{
											title:
												'If set to true, it will remove all non-alphabet characters from a detected word before comparing it to the words in the dictionaries.',
											icon: <InfoCircleFilled />,
										}}
										name='cleaning'
										rules={[{ required: true, message: 'Required' }]}
									>
										<Select className='clean-r' onChange={(e) => onSettingsChange({ cleaning: e })}>
											<Option value='true'>true</Option>
											<Option value='false'>false</Option>
										</Select>
									</Form.Item>
								</div>
							</div>

							<div className='btn_add'>
								<div className='other-btns'>
									<div className='btn-set1'>
										<CustomButton
											className='clear-btn reset'
											onClick={clearAction}
											isDisabled={previewDisable}
										>
											{'Clear'}
										</CustomButton>

										<CustomButton
											className='gray-btn preview'
											onClick={() => setRedactForm(true)}
											type='primary'
											htmlType='submit'
											isLoading={isLocLoading}
											isDisabled={previewDisable}
										>
											{'Preview'}
										</CustomButton>
									</div>

									{adminUserFlag && (
										<div className='btn-set2'>
											<div className='dvider' />
											<CustomButton
												type='primary'
												className='commit'
												isDisabled={commitDisable}
												onClick={() => setModelOpen(true)}
											>
												{'Commit'}
											</CustomButton>
										</div>
									)}
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
			<RedactionModal
				isModalOpen={isModalOpen}
				setModelOpen={setModelOpen}
				envars={envars}
				newWords={newWordList}
				deletedWords={deleteWordArray}
				fileWords={allWords}
				formValues={formValues}
				fileDetails={fileDetails}
				setLocLoading={setPreviewLoading}
				setRedactionResult={setRedactionResult}
				initialValues={initialValues}
				setCommitStatus={setCommitStatus}
				setClickedDeleteList={setClickedDeleteList}
				clickedDeleteList={clickedDeleteList}
				clearAction={clearAction}
				selectedList={selectedList}
				setDeleteFileStatus={setDeleteFileStatus}
			/>
		</>
	);
};

export default Redaction;
