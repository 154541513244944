import Icon, { SyncOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ActionBtnIcon, EditIcon, TrashIcon } from '../../utilities/IconSets';
import { addNumberSuffix } from '../../utilities/addNumberSuffix';
import { Spinner } from '../Loader';
import DeletePolicy from './deletePolicy';
import './style.scss';

const DataDeleteSchedule = ({
	policies,
	updatePolicy,
	setIsLoading,
	tableLoading,
	setUpdateModal,
	setIsAlreadyExist,
}) => {
	const [showAction, setShowAction] = useState('');
	const [deleteModal, setDeleteModal] = useState({ state: false, record: '' });
	const [tableData, setTableData] = useState(policies);

	useEffect(() => {
		setTableData(policies);
	}, [policies]);

	const handleEdit = (record) => {
		setUpdateModal({ state: true, record });
		setIsAlreadyExist(false);
	};

	const handleDelete = (record) => {
		setDeleteModal({ state: true, record });
		setIsAlreadyExist(false);
	};

	const columns = [
		{
			title: 'Buckets',
			dataIndex: 'deletion_mode',
			key: 'deletion_mode',
			render: (deletion_mode) => (
				<>
					{deletion_mode == 'all'
						? 'All data from fiq-screenshots, forwarded and review buckets'
						: 'Masked data from forwarded and review buckets'}
				</>
			),
		},
		{
			title: 'Schedule',
			key: 'cycle',
			render: (record) => {
				const cycleText = record?.cycle == 'monthly' ? 'every month' : 'every quarter';
				return (
					<>
						{record?.trigger_day?.weekday
							? `${record?.trigger_day?.day == 5 ? 'Last' : 'First'} ${
									record?.trigger_day?.weekday
							  } of ${cycleText}`
							: `${addNumberSuffix(record?.trigger_day?.day)} of ${cycleText}`}
					</>
				);
			},
		},
		{
			title: 'Start date',
			dataIndex: 'starting_date',
			key: 'starting_date',
		},
		{
			title: 'Data size',
			dataIndex: 'size',
			key: 'size',
			render: (size) => (
				<>
					{size ? (
						size
					) : (
						<div className='tableInlineImgText'>
							<SyncOutlined style={{ color: '#354f6c' }} spin /> Calculating...
						</div>
					)}
				</>
			),
		},
		{
			title: '',
			key: 'actions',
			width: '10px',
			render: (record) => {
				return (
					<div
						className={`action-icon-box actionIcons`}
						onMouseEnter={() => setShowAction(record.deletion_mode)}
						onMouseLeave={() => setShowAction('')}
					>
						<Icon component={ActionBtnIcon} />
						<div
							className={`action-icon-box-abs no-border ${
								record.deletion_mode !== showAction ? 'hide' : 'visible'
							} `}
						>
							<Tooltip placement='top' title='Edit'>
								<Icon id='editPolicy' component={EditIcon} onClick={() => handleEdit(record)} />
							</Tooltip>
							<Tooltip placement='top' title='Delete'>
								<Icon id='delPolicy' component={TrashIcon} onClick={() => handleDelete(record)} />
							</Tooltip>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<Table
				className='policyStatusTable'
				loading={{
					indicator: <Spinner />,
					spinning: tableLoading,
				}}
				columns={columns}
				dataSource={tableData}
				bordered={false}
				pagination={false}
			/>
			{deleteModal.state && (
				<DeletePolicy
					setDeleteModal={setDeleteModal}
					deleteModal={deleteModal}
					updatePolicy={updatePolicy}
					setIsLoading={setIsLoading}
				/>
			)}
		</>
	);
};

DataDeleteSchedule.propTypes = {
	policies: PropTypes.array,
	updatePolicy: PropTypes.func,
	setIsLoading: PropTypes.func,
	tableLoading: PropTypes.bool,
	setUpdateModal: PropTypes.func,
	setIsAlreadyExist: PropTypes.func,
};

export default DataDeleteSchedule;
