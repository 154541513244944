import { InfoCircleFilled, SyncOutlined } from '@ant-design/icons';
import { DatePicker, Form, Radio, Select, Tooltip, notification } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import ROOT from '../../client';
import { dateFormat } from '../../utilities/constants';
import { days } from '../../utilities/days';
import CustomButton from '../Button';
import DataDeletePolicyTabs from '../DataDeletePolicyTabs';
import UpdatePolicy from '../DataDeleteSchedule/updatePolicy';
import Loader from '../Loader';
import './style.scss';

const timezone = moment.tz.guess();

const { Option } = Select;

const DataDeletePolicy = () => {
	const defaultDate = moment();
	const elasticIndex = 'deletion_policies';
	const [form] = Form.useForm();
	const intervalRef = useRef(null);
	const [day, setDay] = useState(1);
	const [week, setWeek] = useState('monday');
	const [weekOption, setWeekOption] = useState('first');
	const [bucket, setBucket] = useState('');
	const [preserveDay, setPreserveDay] = useState(15);
	const [policies, setPolicies] = useState([]);
	const [policiesLog, setPoliciesLog] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAlreadyExist, setIsAlreadyExist] = useState(false);
	const [lastUpdateData, setLastUpdateData] = useState('');
	const [tableLoading, setTableLoading] = useState(false);
	const [updateModal, setUpdateModal] = useState({ state: false, record: '' });

	const resetStates = () => {
		setIsAlreadyExist(false);
	};

	const resetLoadingStates = () => {
		setIsLoading(false);
		setTableLoading(false);
	};

	const getDataSize = async (policiesArray) => {
		try {
			const newArray = [];
			const { data } = await axios.get(`${ROOT}/api/get-minio-datasize`);
			const { dataSizeRes } = data;
			policiesArray.forEach((val) => {
				if (val?.policy_enabled) {
					let policyData = '';
					if (dataSizeRes.length) {
						policyData = dataSizeRes.find((ds) => ds._id == val.id);
					}
					const { size, unit } = policyData?.data_size;
					newArray.push({
						...val,
						size: `${size} ${unit}`,
					});
				}
			});
			setPolicies(newArray);
		} catch (error) {
			console.error(error);
		}
	};

	const getAllPolicies = async () => {
		try {
			setTableLoading(true);
			const res = await axios.post(`${ROOT}/api/get-all-elastic-records`, {
				index: elasticIndex,
				query: {
					query: {
						match_all: {},
					},
					sort: [
						{
							updated_at: {
								order: 'desc',
							},
						},
					],
				},
			});
			localStorage.setItem('policyLastUpdated', new Date());
			dispatchEvent(new Event('POLICY_UPDATED'));
			const data = res?.data?.body?.hits?.hits || [];
			const policiesArray = [];
			const policiesLog = [];
			if (data.length) {
				const activePolicies = data.filter((val) => val._source?.policy_enabled);
				if (activePolicies.length) {
					activePolicies.forEach((val) => {
						const policyValues = val?._source;
						const obj = {
							...policyValues,
							id: val._id,
							key: val._id,
							size: '',
						};
						policiesArray.push(obj);
						if (policyValues?.deletion_logs?.length) {
							const logs = policyValues?.deletion_logs;
							logs.forEach((log, i) => {
								policiesLog.push({
									...obj,
									key: `${val._id}_${i}`,
									status: log?.status,
									deleted_data_size: `${log?.data_size?.size} ${log?.data_size?.unit}`,
									timestamp: moment
										.tz(log?.date_deleted, timezone)
										.format(`HH:mm:ss z_${dateFormat}`),
								});
							});
						}
					});
				}
				setPoliciesLog(policiesLog);
				setPolicies(policiesArray);
			}
			resetLoadingStates();
			getDataSize(policiesArray);
		} catch (error) {
			resetLoadingStates();
			console.error(error);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		getAllPolicies();
		// Listener triggered when POD Data is updated
		window.addEventListener('POLICY_UPDATED', () => {
			const policyLastUpdated = localStorage.getItem('policyLastUpdated');
			let lastUpdatedDate = moment(policyLastUpdated).format('MMM DD, YYYY HH:mm A');
			setLastUpdateData(lastUpdatedDate);

			intervalRef.current = setInterval(() => {
				const policyLastUpdated = localStorage.getItem('policyLastUpdated');
				let lastUpdatedDate = moment(policyLastUpdated).format('MMM DD, YYYY HH:mm A');
				let timeDiff = moment(policyLastUpdated).fromNow();
				setLastUpdateData(`${lastUpdatedDate} (${timeDiff})`);
			}, 1000 * 60);
		});

		return () => {
			clearInterval(intervalRef.current);
			localStorage.removeItem('policyLastUpdated');
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(async () => {
			getAllPolicies();
		}, 180000);

		return () => clearInterval(interval);
	}, []);

	const getTimestamp = () => {
		return moment().format(`${dateFormat}THH:mm:ss`);
	};

	const updatePolicy = async (record, action) => {
		try {
			setIsLoading(true);
			const updatedValues =
				action == 'delete'
					? `ctx._source.policy_enabled=false;ctx._source.updated_at="${getTimestamp()}";`
					: `ctx._source.updated_at="${getTimestamp()}";ctx._source.data_retention=${
							record?.data_retention
					  };ctx._source.starting_date="${record?.starting_date}";ctx._source.cycle="${
							record?.cycle
					  }";ctx._source.trigger_day=params.trigger_day;`;
			const query = {
				query: {
					bool: {
						must: [
							{
								match: {
									_id: record.id,
								},
							},
						],
					},
				},
				script: {
					source: updatedValues,
					lang: 'painless',
					params:
						action == 'delete'
							? {}
							: {
									trigger_day: record?.trigger_day,
							  },
				},
			};

			const res = await axios.post(`${ROOT}/api/update-elastic-records`, {
				query,
				index: elasticIndex,
			});
			if (res) {
				setIsLoading(false);
				notification.success({
					message: `${action == 'delete' ? 'Deleted' : 'Updated'} successfully.`,
				});
			}
			setTimeout(() => {
				getAllPolicies();
			}, 100);
		} catch (error) {
			console.error(error);
		}
	};

	const onSubmit = async (values) => {
		try {
			const mode = values?.deletion_mode;
			const isExist = policies.find((val) => val.deletion_mode == mode);
			if (isExist) {
				setIsAlreadyExist(true);
				return;
			}

			setIsLoading(true);
			values['trigger_day'] = {
				day:
					values.trigger_day == 'day'
						? day
						: values.trigger_day == 'week' && weekOption == 'first'
						? 1
						: 5,
				weekday: values.trigger_day == 'week' ? week : '',
			};
			values['status'] = 'pending';
			values['created_at'] = getTimestamp();
			values['updated_at'] = getTimestamp();
			values['policy_enabled'] = true;
			values['data_retention'] = preserveDay;
			values['starting_date'] = moment(values.starting_date).format(dateFormat);

			const res = await axios.post(`${ROOT}/api/add-elastic-records`, {
				query: values,
				index: elasticIndex,
			});
			if (res) {
				form.resetFields();
				setDay(1);
				setWeekOption('first');
				setWeek('monday');
				setPreserveDay(15);
				setBucket('');
				setTimeout(() => {
					getAllPolicies();
				}, 100);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleBucketChange = (selectedBucket) => {
		setBucket(selectedBucket);
		resetStates();
	};

	const handleDayChange = (selectedDay) => {
		setDay(selectedDay);
		resetStates();
	};

	const handleWeekOptionChange = (selectedWeekOption) => {
		setWeekOption(selectedWeekOption);
		resetStates();
	};

	const handleWeekChange = (selectedWeek) => {
		setWeek(selectedWeek);
		resetStates();
	};

	const handlePreserveChange = (selectedPreserveDays) => {
		setPreserveDay(selectedPreserveDays);
	};

	return (
		<>
			{isLoading && <Loader />}
			<p style={{ marginBottom: '5px', color: '#000000', lineHeight: '13px' }}>
				<small>
					Setup to auto delete recorded data at a regular cadence. Data will be deleted at 00:00am
					on scheduled days.<br></br> You can setup one auto delete policy per bucket.
				</small>
			</p>
			<Form
				layout='vertical'
				onFinish={onSubmit}
				form={form}
				requiredMark={false}
				className='deleteDataForm'
				initialValues={{
					cycle: 'monthly',
					trigger_day: 'day',
					starting_date: defaultDate,
				}}
				onValuesChange={() => setIsAlreadyExist(false)}
			>
				<div className='policy_wrapper'>
					<div className='policyFormWrapper'>
						<div className='policyFormBlock'>
							<Form.Item
								name='deletion_mode'
								label={<>Select Bucket</>}
								tooltip={{
									title: 'Select whether you want to delete all data or only masked data.',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select
									placeholder='Select Bucket'
									id='deletion_mode'
									onChange={handleBucketChange}
								>
									<Option value='all'>
										All Data <small>(from fiq-screenshots, forwarded and review buckets)</small>
									</Option>
									<Option value='masked'>
										Masked Data <small>(from forwarded and review buckets)</small>
									</Option>
								</Select>
							</Form.Item>
							{bucket == 'all' && (
								<p className='bucketsWarning'>
									<img alt='warning' src='/status-warning.png' /> WARNING: Please note all data will
									be deleted and cannot be recovered.{' '}
								</p>
							)}
							<div className='inlineFields'>
								<Form.Item
									name='cycle'
									label={<>Repeat</>}
									tooltip={{
										title: 'Set up when the deletion of the data will repeat.',
										icon: <InfoCircleFilled />,
									}}
									className='cycleField'
								>
									<Select id='cycle'>
										<Option value='monthly'>Monthly</Option>
										<Option value='quarterly'>Quarterly</Option>
									</Select>
								</Form.Item>
								<Form.Item
									name={'starting_date'}
									label='Start Date'
									rules={[
										{
											required: true,
											message: 'Start date required!',
										},
									]}
								>
									<DatePicker format={dateFormat} />
								</Form.Item>
							</div>
							<Form.Item
								label={<>Delete data on</>}
								name='trigger_day'
								tooltip={{
									title: 'Set up when the deletion of the data will repeat.',
									icon: <InfoCircleFilled />,
								}}
							>
								<Radio.Group className='checkbox_wrapper'>
									<div className='optionWrapper'>
										<Radio value={'day'}></Radio>
										<div className='dayOption'>
											<Select id='dayOptions' value={day} onChange={handleDayChange}>
												{days.map((num) => {
													return (
														<Option key={num} value={num}>
															{num}
														</Option>
													);
												})}
											</Select>
											<p>day</p>
										</div>
									</div>
									<div className='optionWrapper'>
										<Radio value={'week'}></Radio>
										<div className='weekOption'>
											<Select id='weekOptions' value={weekOption} onChange={handleWeekOptionChange}>
												<Option value={'first'}>First</Option>
												<Option value={'last'}>Last</Option>
											</Select>
											<Select id='weeks' value={week} onChange={handleWeekChange}>
												<Option value={'monday'}>Monday</Option>
												<Option value={'tuesday'}>Tuesday</Option>
												<Option value={'wednesday'}>Wednesday</Option>
												<Option value={'thursday'}>Thursday</Option>
												<Option value={'friday'}>Friday</Option>
												<Option value={'saturday'}>Saturday</Option>
												<Option value={'sunday'}>Sunday</Option>
											</Select>
										</div>
									</div>
								</Radio.Group>
							</Form.Item>
							<Form.Item>
								<div className='preserveWrapper'>
									<p>Exclude data from last </p>
									<Select id='preserveDays' value={preserveDay} onChange={handlePreserveChange}>
										{days.map((num) => {
											return (
												<Option key={num} value={num}>
													{num}
												</Option>
											);
										})}
									</Select>
									<p>days from deletion </p>
								</div>
							</Form.Item>
						</div>
						{isAlreadyExist && (
							<p className='validateError' style={{ marginBottom: '10px' }}>
								A delete policy for this bucket already exists. Change the bucket and try again.
							</p>
						)}
					</div>

					<CustomButton
						isDisabled={bucket ? false : true}
						className='deployBtn'
						type='primary'
						htmlType='submit'
					>
						SAVE
					</CustomButton>
				</div>
			</Form>
			<div className='policyStatusWrapper'>
				{lastUpdateData && (
					<div className='policyRefreshWrapper'>
						<div className='pod_header_update'>Last Updated {lastUpdateData}</div>
						<Tooltip placement='bottom' arrowPointAtCenter title='Refresh'>
							<CustomButton
								size='small'
								className='btnRefresh'
								type='primary'
								icon={<SyncOutlined />}
								isDisabled={tableLoading}
								onClick={() => getAllPolicies()}
							/>
						</Tooltip>
					</div>
				)}

				<DataDeletePolicyTabs
					policies={policies}
					policiesLog={policiesLog}
					updatePolicy={updatePolicy}
					setIsLoading={setIsLoading}
					tableLoading={tableLoading}
					setUpdateModal={setUpdateModal}
					setIsAlreadyExist={setIsAlreadyExist}
				/>
				<UpdatePolicy
					setUpdateModal={setUpdateModal}
					updateModal={updateModal}
					updatePolicy={updatePolicy}
					setIsLoading={setIsLoading}
				/>
			</div>
		</>
	);
};

export default DataDeletePolicy;
