import {
	EyeInvisibleOutlined,
	InfoCircleFilled,
	InfoCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Form, Input, Radio, Select, Tooltip, notification } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorIcon from '../../../Images/form-error.svg';
import { GET_PREDEFINED_REGEX_API, REDACTION_API, createMC } from '../../../services';
import { SearchOutLined } from '../../../utilities/IconSets';
import AddScope from '../../AddScope';
import CustomButton from '../../Button';
import customTost from '../../Notification';
import { getElasticQuery } from '../../utilities/queryBuilder';
import { getSignedUrl } from '../redaction';
import RegexModel from './regexModel';
import RegexTable from './regexTable';
import { ReplaceWordsBadges } from './replaceBadges';
import './styles.scss';
const { TextArea } = Input;

const { Option } = Select;
const DESCRIPTION = 'description';

// info component that adds info icon and text
export const InfoNote = (n) => (
	<div className={`info-wrapper ${n.className}`}>
		<InfoCircleOutlined />
		<span>{n.text}</span>
	</div>
);

// A function that checks if a regex pattern is valid and non-empty
const isValidRegex = (pattern) => {
	if (pattern?.trim() === '') {
		// If yes, return false
		return false;
	}
	// Otherwise, try to create a new RegExp object with the given pattern
	try {
		new RegExp(pattern);
	} catch (e) {
		// If an error is thrown, the pattern is invalid
		return false;
	}
	return true;
};

// custom error component
const CustomError = (p) => {
	if (!p.err?.error) {
		return null;
	}

	return (
		<div className='regex-error'>
			<div className='err-wrapper'>
				<img src={ErrorIcon} alt='err-icon' className='err-icon' />
				<span>{p.err.error} </span>
			</div>
			<span>{p.err?.title}</span>
		</div>
	);
};

// format ant error into custom error
const formatErrData = (arr, type, data) => {
	return arr.map((i) =>
		i.type === type
			? {
					...i,
					state: data.state,
					error: data.error,
					title: data.title,
			  }
			: i,
	);
};

const RegexRedaction = (props) => {
	const [form] = Form.useForm();
	const { envars, adminUserFlag, mappingProps } = useSelector((store) => store.storeProps);
	const minioClient = createMC(envars);
	const {
		getSelectedImg,
		handleRedactionProcess,
		previewView,
		setPreviewView,
		setPreviewLoading,
		previewLoading,
		setCurrentRegex,
		currentWords,
		redactionSettings,
		renderBoundingBox,
		setRedactionResult,
		setOcr,
		ocrData,
		commitDisable,
		setCommitDisable,
	} = props;
	const [formValues, setFormValues] = useState({});
	const [regexData, setRegexData] = useState([]);
	const [filteredRegexData, setFilteredRegexData] = useState([]);
	const [isModalOpen, setIsModelOpen] = useState(false);
	const [previewDisable, setPreviewDisable] = useState(true);
	// const [commitDisable, setCommitDisable] = useState(true);
	const [regexFileData, setRegexFileData] = useState({});
	const [editView, setEditView] = useState({ state: false, data: '' });
	const [isLoading, setLoading] = useState(false);
	const [deleteView, setDeleteView] = useState({ state: false, data: '' });
	const [searchField, setSearchField] = useState(DESCRIPTION);
	const [searchValue, setSearchValue] = useState({ value: '', type: '' });
	const [showRegexForm, setShowRegexForm] = useState(false);
	const [fieldErrs, setFieldErrs] = useState([
		{ state: false, type: 'regex', error: '' },
		{ state: false, type: 'des', error: '' },
	]);
	const [replaceWordsList, setReplaceWordsList] = useState([]);
	const [duplicateWord, setDuplicateWord] = useState('');
	const [actionType, setActionType] = useState(false);
	const [replaceActionType, setReplaceActionType] = useState(false);
	const [replacementActionValue, setReplacementActionValue] = useState('user');
	const replaceTextareaRef = useRef(null);
	const [formRowData, setFormRowData] = useState([
		{
			key: 0,
			field: '',
			fieldType: '',
			operand: '',
			operandOptions: [],
		},
	]);
	const [filterGroups, setFilterGroups] = useState([]);
	const [scopeQueryString, setScopeQueryString] = useState('');
	const [initialScopeData, setInitialScopeData] = useState([]);
	const [regexType, setRegexType] = useState('custom');
	const [predefinedRegexOptions, setPredefinedRegexOptions] = useState([]);
	const [elasticQuery, setElasticQuery] = useState('');
	const [allowAllWarning, setAllowAllWarning] = useState(false);

	const descriptionSuffix =
		searchValue?.value?.length === 0 ? (
			<Tooltip title='Extra information'>
				<SearchOutLined />
			</Tooltip>
		) : (
			<span />
		);

	const getPredefinedRegex = async () => {
		const { data } = await GET_PREDEFINED_REGEX_API();
		if (data.statusCode === 200) {
			const ner = data.NER;
			setPredefinedRegexOptions(ner);
		}
	};

	useEffect(() => {
		// fetch predefined regex
		getPredefinedRegex();
		// fetch regex data from server
		getRegexData();
		// after data fetch setPreviewView into false
		setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
	}, []);

	useEffect(() => {
		if (previewView?.enable) {
			setPreviewDisable(ocrData?.length ? false : true);
		}
	}, [ocrData]);

	const setPreviewBtnState = () => {
		const customRegexVal = form.getFieldValue('regex');
		const descriptionVal =
			regexType === 'predefined'
				? `Predefined-${customRegexVal}`
				: form.getFieldValue('description');
		if (customRegexVal || descriptionVal) {
			let previewBtnDisableState = fieldErrs[0]?.state || fieldErrs[1]?.state ? true : false;
			if (replaceActionType) {
				previewBtnDisableState =
					replaceWordsList.length && !fieldErrs[0]?.state && !fieldErrs[1]?.state ? false : true;
			}
			setPreviewDisable(previewBtnDisableState);
		}
	};

	useEffect(() => {
		setPreviewBtnState();
	}, [fieldErrs, replaceWordsList, replaceActionType]);

	/* istanbul ignore next */
	const generateElasticQuery = () => {
		const query = getElasticQuery(filterGroups, mappingProps);
		setElasticQuery(query);
	};
	/* istanbul ignore next */
	useEffect(() => {
		if (filterGroups.length) {
			generateElasticQuery();
			if (editView?.state) {
				setPreviewDisable(false);
			}
		}
	}, [filterGroups]);

	useEffect(() => {
		manageFormValidation();
	}, [scopeQueryString]);
	/* istanbul ignore next */
	useEffect(() => {
		if (editView.state) {
			setPreviewDisable(false);
		}
	}, [editView]);
	/* istanbul ignore next */
	const compareValues = async () => {
		let isMatched = {
			regex: false,
			description: false,
			scope: false,
		};
		if (regexFileData.length) {
			const formValues = form.getFieldsValue();

			// compare regex
			let matchedRegex = regexFileData.filter(
				(val) => val?.regex?.toLowerCase() === formValues?.regex?.toLowerCase(),
			);

			if (matchedRegex?.length) {
				isMatched = { ...isMatched, regex: true };
				if (editView?.state) {
					const valueIndex = matchedRegex.findIndex(
						(item) => item.scopeQueryString === editView?.data?.scopeQueryString,
					);
					if (valueIndex !== -1) {
						matchedRegex.splice(valueIndex, 1);
					}
				}
				const scopeVal = matchedRegex.filter(
					(matchItem) => matchItem.scopeQueryString?.trim() === scopeQueryString?.trim(),
				);
				if (scopeVal.length) {
					isMatched = { ...isMatched, scope: true };
				}

				// compare scope
				for (let r = 0; r < matchedRegex.length; r++) {
					const existingScopeQuery = matchedRegex[r].scopeQueryString;
					const scopeData = matchedRegex[r].scopeData;
					if (existingScopeQuery && scopeQueryString) {
						if (scopeData?.length === formRowData?.length) {
							let sameScope = false;
							for (let i = 0; i < formRowData.length; i++) {
								if (formRowData[i].field == 'observed_at') {
									let isDateMatched = scopeData.find((x) => {
										return x?.startDate == formRowData[i]?.startDate &&
											x?.operator == formRowData[i]?.operator
											? x
											: false;
									});
									isDateMatched = scopeData.find((y) => {
										return y?.endDate == formRowData[i]?.endDate &&
											y?.operator == formRowData[i]?.operator
											? y
											: false;
									});
									sameScope = isDateMatched ? true : false;
								} else {
									let isValueMatched = scopeData.find((z) => {
										return z?.value == formRowData[i]?.value &&
											z?.field == formRowData[i]?.field &&
											z?.operand == formRowData[i]?.operand
											? z
											: false;
									});
									sameScope = isValueMatched ? true : false;
								}
							}
							if (sameScope) {
								isMatched = { ...isMatched, scope: true };
								break;
							}
						} else {
							isMatched = { ...isMatched, scope: false };
						}
					}
				}
			}

			// compare description
			for (let m = 0; m < regexFileData.length; m++) {
				const regexVal = regexFileData[m].regex;
				const desVal =
					regexType === 'predefined' ? `Predefined-${regexVal}` : regexFileData[m].description;
				if (
					desVal?.toLowerCase() === formValues?.description?.toLowerCase() &&
					regexType !== 'predefined'
				) {
					isMatched = { ...isMatched, description: true };
				}
			}
		}
		if (!isMatched.scope && isMatched.regex && scopeQueryString) {
			isMatched = { ...isMatched, regex: false };
		}
		return isMatched;
	};

	const manageFormValidation = async () => {
		const formValues = form.getFieldsValue();
		const isAnyMatch = await compareValues();
		let isRegexExist = isAnyMatch.regex;
		let isDesExist = isAnyMatch.description;

		if (editView?.state) {
			isRegexExist = formValues?.regex
				? formValues?.regex?.trim().toLowerCase() === editView?.data?.regex?.trim().toLowerCase() &&
				  !isAnyMatch.scope
					? false
					: isRegexExist
				: false;
			isDesExist = formValues?.description
				? formValues?.description?.trim().toLowerCase() ===
				  editView?.data?.description?.trim().toLowerCase()
					? false
					: isDesExist
				: false;
		}

		let err = [
			{
				type: 'regex',
				state: !formValues?.regex ? true : isRegexExist ? true : false,
				error: isRegexExist
					? isAnyMatch.scope
						? 'This regex and scope combination already exists.'
						: `This regex already exists.`
					: '',
				title: isRegexExist ? 'Check the table to find the existing regex' : '',
			},
		];
		if (regexType !== 'predefined') {
			err.push({
				type: 'des',
				state: !formValues?.description ? true : isDesExist ? true : false,
				error: isDesExist ? `This description already exists.` : '',
				title: isDesExist ? 'Check the table to find the existing description' : '',
			});
		}
		setFieldErrs(err);
		if (!commitDisable) {
			setCommitDisable(true);
			if (previewView?.data) {
				setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
				handleRedactionProcess('');
			}
		}
	};

	// common function for form field's onChange
	const handleValuesChange = async (changedValues) => {
		setCommitDisable(true);
		if ('regex' in changedValues || 'description' in changedValues) {
			manageFormValidation();
		}
	};

	// hit /redaction-mask API if previewView state is true
	useEffect(() => {
		if (previewView?.state) {
			onSubmit(previewView?.data);
		}
	}, [previewView]);

	// fetch regex data from minio storage
	const getRegexData = () => {
		const chunks = [];
		let fileString = '';
		/* istanbul ignore next */
		try {
			minioClient.getObject('dictionaries', 'regex.json', function (err, dataStream) {
				if (err) {
					return console.error(err);
				}
				dataStream.on('data', function (chunk) {
					chunks.push(Buffer.from(chunk));
				});
				dataStream.on('end', function () {
					fileString = Buffer.concat(chunks).toString('utf-8');
					const parseObj = JSON.parse(fileString);
					setRegexFileData(parseObj);
					setRegexData(parseObj);
				});
				dataStream.on('error', function (err) {
					console.error(err);
				});
			});
		} catch (err) {
			/* tslint:disable:no-empty */
		}
	};

	const resetScopeStates = () => {
		setInitialScopeData([]);
		setFormRowData([
			{
				key: 0,
				field: '',
				fieldType: '',
				operand: '',
				operandOptions: [],
			},
		]);
		setScopeQueryString('');
	};

	const resetRegexStates = () => {
		setRegexType('custom');
		setReplacementActionValue('user');
	};

	// update refex data on minio storage
	const updateRegexData = (fileStream, action) => {
		/* istanbul ignore next */
		try {
			minioClient.putObject(
				'dictionaries',
				'regex.json',
				JSON.stringify(fileStream),
				function (err) {
					if (err) return;
					getRegexData();
					action === 'delete' ? setDeleteView({ state: false, data: '' }) : handleCancel();
					setLoading(false);
					setPreviewLoading(false);
					setRedactionResult([]);
					handleRedactionProcess('');
					customTost({
						type: 'success',
						message: `${action === 'delete' ? 'Deleted' : 'Commit'} successfully.`,
					});
				},
			);
			resetScopeStates();
			resetRegexStates();
			setAllowAllWarning(false);
		} catch (err) {
			console.error(err);
		}
	};

	// submit regex form and get redacted image URL
	const onSubmit = async (values) => {
		try {
			if (!ocrData.length) {
				notification.warning({
					message: 'Preview is unavailable as the OCR of this image does not exist.',
				});
				return;
			}
			if (!previewLoading) {
				setPreviewLoading(true);
			}

			const regexData = {
				id: editView?.state ? editView?.data?.id : `${values?.type}_${new Date().getTime()}`,
				key: editView?.state ? editView?.data?.key : `${values?.type}_${new Date().getTime()}`, // for table key
				source: 'custom_regex',
				description: values.description.trim(),
				regex: values.regex ? values.regex.trim() : '',
				predefined_system: regexType === 'predefined' ? (values.regex ? values.regex : '') : '',
				type: values.anonymizeType === 'system' ? 'replace_system' : values.type,
				anonymizeType: values.anonymizeType,
				placeholders: replaceWordsList,
				scopeData: formRowData,
				scopeQueryString,
				scopeQuery: editView?.state
					? filterGroups.length
						? elasticQuery
						: scopeQueryString
						? editView?.data?.scopeQuery
						: ''
					: elasticQuery,
			};
			const newRegexObj =
				'id' in values
					? values
					: editView?.state
					? { ...regexData, createdAt: editView?.data?.createdAt, updatedAt: new Date() }
					: { ...regexData, createdAt: new Date(), updatedAt: new Date() };
			setFormValues(newRegexObj);
			setSearchField(DESCRIPTION);
			setSearchValue({ value: '', type: '' });
			const imgId = getSelectedImg();
			const imgKey = imgId.split('.');
			// setCurrentRegex(newRegexObj);
			let payload = {
				UUID: imgKey[0],
				custom_regex: [newRegexObj],
				...redactionSettings,
				...currentWords,
				edit_regex: editView?.state ? editView?.data?.id : '',
			};
			if (previewView?.state && previewView?.singlePreview) {
				payload = { ...payload, preview: true };
			}
			if (editView?.state) {
				payload = { ...payload, preview: true };
			}
			// if (replacementActionValue !== 'system') {
			const res = await REDACTION_API(payload);
			const { data } = res;
			if (data.statusCode === 200) {
				const maskedImg = await getSignedUrl(envars, `${imgKey[0]}_redacted.${imgKey[1]}`);
				handleRedactionProcess(maskedImg);
				setCommitDisable(adminUserFlag ? false : true);
				/* istanbul ignore next */
				setTimeout(() => {
					renderBoundingBox(res?.data?.redacted_ocr);
					setOcr(res?.data?.redacted_ocr);
					customTost({
						type: 'success',
						message: data?.message ? data.message : 'Redacted Successfully!.',
					});
				}, 2000);
				// setPreviewLoading(false);
				setPreviewView({
					enable: true,
					state: false,
					data: newRegexObj,
					singlePreview: previewView?.singlePreview,
				});
				document?.querySelector?.('.carousel') && document.querySelector('.carousel').focus();
			} else {
				customTost({
					type: 'error',
					message: data?.message || 'Internal server error.',
				});
				setCommitDisable(true);
				setPreviewLoading(false);
			}
		} catch (err) {
			console.error(err);
			setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
			customTost({
				type: 'error',
				message: err?.response?.data?.message
					? err?.response?.data?.message
					: 'Internal server error.',
			});
			handleRedactionProcess('');
			setPreviewLoading(false);
		}
	};

	// handle cancle action and reset state accordingly
	const handleCancel = () => {
		setReplaceWordsList([]);
		setActionType(false);
		setReplaceActionType(false);
		setShowRegexForm(false);
		form.resetFields();
		setFormValues({});
		setCommitDisable(true);
		setPreviewDisable(true);
		setEditView({ state: false, data: '' });
		setPreviewView({ enable: false, state: false, data: '', singlePreview: false });
		setFieldErrs([
			{ state: false, type: 'regex', error: '' },
			{ state: false, type: 'des', error: '' },
		]);
		handleRedactionProcess('');
		setSearchField(DESCRIPTION);
		setSearchValue({ value: '', type: '' });
		setCurrentRegex('');
		setOcr([]);
		setRedactionResult([]);
		resetScopeStates();
		resetRegexStates();
		setAllowAllWarning(false);
		setDuplicateWord('');
	};

	const deleteItem = (word) => {
		try {
			const newWordsArray = replaceWordsList.filter(
				(val) => val.toLowerCase() !== word.toLowerCase(),
			);
			setReplaceWordsList(newWordsArray);
			setCommitDisable(true);
			handleRedactionProcess('');
			setRedactionResult([]);
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddWords = (e) => {
		try {
			const disabledOn = e.relatedTarget;
			// disable it if user clicked on the delete icon
			if (disabledOn?.classList.contains('delete-form')) return;

			const word = e?.target?.value?.trim();
			if (word) {
				let isExist = '';
				if (replaceWordsList.length) {
					isExist = replaceWordsList.find((val) => val.toLowerCase() == word.toLowerCase());
					setDuplicateWord(isExist ? word : '');
				}
				if (!isExist) {
					setReplaceWordsList([...replaceWordsList, word]);
					form.resetFields(['replace']);
					setTimeout(() => {
						replaceTextareaRef.current.focus();
					}, 0);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleEnterKeyPress = (e) => {
		// handle enter event
		if (e.keyCode === 13) {
			handleAddWords(e);
		}
	};

	const handleActionChange = (e) => {
		setActionType(e?.target?.value === 'replace' ? true : false);
		setReplaceActionType(
			e?.target?.value === 'replace' && replacementActionValue === 'user' ? true : false,
		);
		const replaceActionValue = form.getFieldValue('anonymizeType');
		// if (predefinedRegex && replaceActionValue === 'system') {
		// 	setPreviewDisable(false);
		// }
		if (
			!replaceWordsList.length &&
			!previewDisable &&
			e?.target?.value === 'replace' &&
			replaceActionValue !== 'system'
		) {
			setPreviewDisable(true);
		}
	};

	const handleReplaceActionChange = (e) => {
		setReplaceActionType(e?.target?.value === 'user' ? true : false);
		setReplacementActionValue(e?.target?.value);
	};
	/* istanbul ignore next */
	useEffect(() => {
		if (editView.data) {
			const editRowData = editView.data;
			form.setFieldsValue(editRowData);
			if (editRowData.predefined_system) {
				setRegexType('predefined');
				form.setFieldsValue({
					predefined: editRowData.predefined_system,
				});
			} else {
				setRegexType('custom');
				form.setFieldsValue({
					custom: editRowData.regex,
				});
			}
			if (editRowData.type === 'replace_system') {
				form.setFieldsValue({
					type: 'replace',
				});
				setActionType(true);
			}
			const scopeData = editRowData?.scopeData;
			setScopeQueryString(editRowData?.scopeQueryString);
			setFormRowData(
				scopeData?.length
					? scopeData
					: [
							{
								key: 0,
								field: '',
								fieldType: '',
								operand: '',
								operandOptions: [],
							},
					  ],
			);
			let formInitialValues = [];
			if (scopeData?.length) {
				formInitialValues = scopeData.map((val) => {
					return {
						field: val.field,
						operand: val.operand,
						value: val.value,
						operator: val.operator == 'AND' ? true : false,
					};
				});
			}

			setInitialScopeData(formInitialValues);
		}
	}, [editView]);

	const handleRegexChange = (e) => {
		const regexText = e?.target?.value || '';
		const allAllowState = regexText == '#!%AllowAllDisableRedactionTurnOffMasking^&*';
		if (allAllowState) {
			form.setFieldsValue({ type: 'pass' });
			if (actionType) {
				setActionType(false);
			}
			if (replaceActionType) {
				setReplaceActionType(false);
			}
		}
		setAllowAllWarning(allAllowState ? true : false);
	};

	const handleRegexType = (e) => {
		setRegexType(e);
		const actionType = form.getFieldValue('type');
		if (e === 'custom') {
			form.setFieldsValue({
				anonymizeType: 'user',
			});
			setReplaceActionType(actionType === 'replace' ? true : false);
			setReplacementActionValue('user');
			form.resetFields(['description', 'regex']);
		} else {
			form.setFieldsValue({
				anonymizeType: 'system',
			});
			setReplaceActionType(false);
			setReplacementActionValue('system');
			form.resetFields(['description', 'regex']);
		}
		setPreviewDisable(true);
		setCommitDisable(true);
	};

	return (
		<>
			<div className='regex-container'>
				{showRegexForm ? (
					<div>
						<div className='regex-form-header'>
							<Text>{editView?.state ? 'Edit' : 'Create'} regex expression</Text>
						</div>

						<AddScope
							setFormRowData={setFormRowData}
							formRowData={formRowData}
							setFilterGroups={setFilterGroups}
							setScopeQueryString={setScopeQueryString}
							scopeQueryString={scopeQueryString}
							initialScopeData={initialScopeData}
							setInitialScopeData={setInitialScopeData}
							setPreviewDisable={setPreviewDisable}
						/>

						{fieldErrs.map((val) => {
							return <CustomError err={val} key={val?.type} />;
						})}
						{allowAllWarning && (
							<div className='regex-error'>
								<div className='err-wrapper'>
									<img src={ErrorIcon} alt='err-icon' className='err-icon' />
									<span>Warning! </span>
								</div>
								<ul>
									<li>The regex will bypass redaction on applicable images in scope.</li>
									{!scopeQueryString && <li>Scope is required.</li>}
								</ul>
							</div>
						)}
						<Form
							initialValues={{ type: 'block', anonymizeType: 'user' }}
							layout='vertical'
							onFinish={onSubmit}
							onValuesChange={handleValuesChange}
							form={form}
							requiredMark={false}
							className='regex-form'
						>
							{/* <Form.Item
								name='regex'
								className='form-item'
								label={
									<>
										Regex expression <span style={{ color: 'red' }}>*</span>
									</>
								}
								rules={[
									{
										validator: (_, value) => {
											const isValid = isValidRegex(value);

											if (isValid) {
												return Promise.resolve();
											}
											const err = formatErrData(fieldErrs, 'regex', {
												state: true,
												error: 'Invalid regular expression',
												title: 'Correct the expression and try again',
											});
											return Promise.reject(setFieldErrs(err));
										},
									},
								]}
							>
								<Input onChange={handleRegexChange} placeholder='[abc]\g' className='regex-input' />
							</Form.Item> */}
							<div className='regexType'>
								<label>Regex expression</label>
								<Select
									value={regexType}
									className='select-before regexTypeField'
									onChange={(e) => handleRegexType(e)}
								>
									<Option value='custom'>Custom</Option>
									<Option value='predefined'>Predefined</Option>
								</Select>
							</div>
							<div className='regex-header regexTypeSection'>
								{regexType === 'custom' ? (
									<Form.Item
										name='regex'
										className='form-item'
										rules={[
											{
												validator: (_, value) => {
													const isValid = isValidRegex(value);

													if (isValid) {
														return Promise.resolve();
													}
													const err = formatErrData(fieldErrs, 'regex', {
														state: true,
														error: 'Invalid regular expression',
														title: 'Correct the expression and try again',
													});
													return Promise.reject(setFieldErrs(err));
												},
											},
										]}
									>
										<Input
											className='customRegexInput'
											onChange={handleRegexChange}
											placeholder='[abc]\g'
										/>
									</Form.Item>
								) : (
									<Form.Item name='regex' className='form-item'>
										<Select
											onSelect={(e) => {
												form.setFieldsValue({ description: `Predefined-${e}` });
											}}
											showSearch
											className='predefinedField'
										>
											{predefinedRegexOptions.map((val) => {
												return (
													<Option key={val} value={val}>
														{val}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								)}
							</div>
							<Form.Item
								className='form-item'
								name='description'
								label={
									<>
										Description <span style={{ color: 'red' }}>*</span>
									</>
								}
								rules={[
									{
										min: 5,
										message: () => {
											setFieldErrs(() =>
												formatErrData(fieldErrs, 'des', {
													state: true,
													error: 'Description field',
													title: 'Please enter at least 5 characters',
												}),
											);
										},
									},
									{
										max: 100,
										message: () => {
											setFieldErrs(() =>
												formatErrData(fieldErrs, 'des', {
													state: true,
													error: 'Description field',
													title: ' Maximum limit is 100 characters',
												}),
											);
										},
									},
								]}
							>
								<TextArea
									disabled={regexType === 'predefined' ? true : false}
									placeholder='Add description'
									className='regex-input regex-des'
								/>
							</Form.Item>

							<Form.Item className='form-item' name='type' label={<>Action</>}>
								<Radio.Group className='checkbox-wrapper actionRadio' onChange={handleActionChange}>
									<Radio value={'pass'}>Allow</Radio>
									<Radio disabled={allowAllWarning} value={'block'}>
										Block
									</Radio>
									<Radio disabled={allowAllWarning} value={'replace'}>
										Replace with
									</Radio>
								</Radio.Group>
							</Form.Item>

							{actionType && (
								<div className='replaceSection'>
									<Form.Item className='form-item' name='anonymizeType'>
										<Radio.Group
											className='checkbox-wrapper anonymizeTypeRadio'
											onChange={handleReplaceActionChange}
										>
											<Radio disabled={regexType === 'predefined' ? false : true} value={'system'}>
												System Generated
											</Radio>
											<Radio value={'user'}>
												User Input{' '}
												<Tooltip title='Words used for replacement from user input will be randomly selected from the list of values below.'>
													<InfoCircleFilled />
												</Tooltip>
											</Radio>
										</Radio.Group>
									</Form.Item>
									{replaceActionType && (
										<>
											<p className='noteForUserInput'>
												Note: Any replacement with user input will be random selection from the list
												of values below.
											</p>
											<Form.Item className='form-item' name='replace'>
												<div className='replaceWordsTextareaBlock'>
													<div className='replaceWordsTextarea'>
														{replaceWordsList.length > 0 && (
															<ReplaceWordsBadges
																deleteItem={deleteItem}
																list={replaceWordsList}
																duplicateWord={duplicateWord}
															/>
														)}
														<TextArea
															ref={replaceTextareaRef}
															className='replaceInput'
															onBlur={handleAddWords}
															onKeyDown={handleEnterKeyPress}
														/>
													</div>
													{/* <p>Max characters 25</p> */}
												</div>
											</Form.Item>
										</>
									)}
								</div>
							)}

							<div className='btn-container'>
								<CustomButton onClick={handleCancel} isDisabled={false}>
									Cancel
								</CustomButton>
								<CustomButton
									className={'r-preview'}
									type='primary'
									htmlType='submit'
									isDisabled={
										allowAllWarning ? (scopeQueryString ? previewDisable : true) : previewDisable
									}
								>
									Preview
								</CustomButton>

								<CustomButton
									type='primary'
									onClick={() => setIsModelOpen(true)}
									isDisabled={commitDisable}
								>
									Commit
								</CustomButton>
							</div>
							{/* <InfoNote
								text={
									'Redaction results are from all configured regex, including the current regex'
								}
							/> */}
						</Form>
					</div>
				) : (
					<>
						<div className='regex-header'>
							<Select
								defaultValue='description'
								className='select-before'
								bordered={false}
								onChange={(e) => {
									setSearchField(e);
									setSearchValue({ value: '', type: '' });
								}}
							>
								<Option value={DESCRIPTION}>Description</Option>
								<Option value='action'>Action</Option>
							</Select>
							{searchField === DESCRIPTION ? (
								<Input
									placeholder='Search'
									bordered={false}
									suffix={descriptionSuffix}
									onChange={(e) => {
										setSearchValue({ value: e?.target?.value, type: 'desc' });
										setFilteredRegexData(
											regexData.filter((value) =>
												value.description.toLowerCase().includes(e.target.value.toLowerCase()),
											),
										);
									}}
								/>
							) : (
								<Select
									allowClear
									bordered={false}
									onClear={() => {
										setSearchValue({ value: '', type: '' });
										setFilteredRegexData(regexData);
									}}
									className='select-before'
									placeholder='Select action'
									onChange={(e) => {
										setSearchValue({ value: e, type: 'action' });
										setFilteredRegexData(regexData.filter((value) => value.type === e));
									}}
								>
									<Option value='pass'>Allow</Option>
									<Option value='block'>Block</Option>
									<Option value='replace'>User Input</Option>
								</Select>
							)}
						</div>
						<div className='expression-wrapper'>
							<Text className='expr-text'>Expressions</Text>
							<div className='expression-icons'>
								{previewView?.data && (
									<Tooltip title='Disable Preview View'>
										<EyeInvisibleOutlined
											className='dis-icon'
											onClick={() => {
												setPreviewView({
													enable: false,
													state: false,
													data: '',
													singlePreview: false,
												});
												handleRedactionProcess('');
												setRedactionResult([]);
											}}
										/>
									</Tooltip>
								)}

								<Tooltip title='Create regex'>
									<PlusOutlined
										id='openRegexFormIcon'
										onClick={() => {
											setActionType(false);
											setReplaceActionType(false);
											setReplaceWordsList([]);
											setPreviewDisable(true);
											setCommitDisable(true);
											setShowRegexForm(true);
											setPreviewView({
												enable: false,
												state: false,
												data: '',
												singlePreview: false,
											});
											handleRedactionProcess('');
											setRedactionResult([]);
										}}
									/>
								</Tooltip>
							</div>
						</div>
						<RegexTable
							regexData={searchValue.value ? filteredRegexData : regexData}
							setEditView={setEditView}
							setPreviewView={setPreviewView}
							isLoading={isLoading}
							setShowRegexForm={setShowRegexForm}
							setPreviewDisable={setPreviewDisable}
							handleRedactionProcess={handleRedactionProcess}
							setCommitDisable={setCommitDisable}
							setIsModelOpen={setIsModelOpen}
							setDeleteView={setDeleteView}
							setReplaceWordsList={setReplaceWordsList}
							setActionType={setActionType}
							setReplaceActionType={setReplaceActionType}
							setRedactionResult={setRedactionResult}
							setCurrentRegex={setCurrentRegex}
							setOcr={setOcr}
						/>
						{/* <InfoNote
							className='regex-info'
							text={'Redaction results are from the current regex only'}
						/> */}
					</>
				)}

				<RegexModel
					isModalOpen={isModalOpen}
					setIsModelOpen={setIsModelOpen}
					deleteView={deleteView}
					setLoading={setLoading}
					setPreviewLoading={setPreviewLoading}
					regexFileData={regexFileData}
					updateRegexData={updateRegexData}
					editView={editView}
					formValues={formValues}
					scopeQueryString={scopeQueryString}
				/>
			</div>
		</>
	);
};

RegexRedaction.propTypes = {
	isLocLoading: PropTypes.bool,
	getSelectedImg: PropTypes.func,
	handleRedactionProcess: PropTypes.func,
	setPreviewView: PropTypes.func,
	previewView: PropTypes.object,
	setPreviewLoading: PropTypes.func,
	previewLoading: PropTypes.bool,
	setCurrentRegex: PropTypes.func,
	currentWords: PropTypes.any,
	redactionSettings: PropTypes.any,
	renderBoundingBox: PropTypes.func,
	setRedactionResult: PropTypes.func,
	setOcr: PropTypes.func,
	ocrData: PropTypes.any,
	commitDisable: PropTypes.bool,
	setCommitDisable: PropTypes.func,
};

export default RegexRedaction;
