/* istanbul ignore next */
const queryBuilder = (sqlQuery, mapping, must) => {
	let match = {};
	let operator = sqlQuery.operator;
	if (operator == 'is') {
		if (!mapping[sqlQuery.key]?.type) return null;

		let keyForObj = mapping[sqlQuery.key].type == 'keyword' ? 'term' : 'match';
		match[
			mapping[sqlQuery.key].type == 'keyword' ||
			mapping[sqlQuery.key].type == 'boolean' ||
			mapping[sqlQuery.key].type == 'long' ||
			mapping[sqlQuery.key].type == 'integer' ||
			mapping[sqlQuery.key].type == 'date'
				? `${sqlQuery.key}`
				: `${sqlQuery.key}.keyword`
		] = sqlQuery.value;
		let obj = {};
		obj[`${keyForObj}`] = match;
		must.push(obj);
	} else if (operator == 'Not Like') {
		let keyForObj = 'match';

		match[`${sqlQuery.key}`] = sqlQuery.value;
		let obj = {};
		obj[`${keyForObj}`] = match;
		must.push({
			bool: {
				must_not: [obj],
			},
		});
	} else if (operator == 'Like') {
		let keyForObj = 'match';

		match[`${sqlQuery.key}`] = sqlQuery.value;
		let obj = {};
		obj[`${keyForObj}`] = match;
		must.push(obj);
	} else if (operator == 'is not') {
		if (!mapping[sqlQuery.key]?.type) return null;

		let keyForObj = mapping[sqlQuery.key].type == 'keyword' ? 'term' : 'match';
		match[
			mapping[sqlQuery.key].type == 'keyword' ||
			mapping[sqlQuery.key].type == 'boolean' ||
			mapping[sqlQuery.key].type == 'long' ||
			mapping[sqlQuery.key].type == 'integer' ||
			mapping[sqlQuery.key].type == 'date'
				? `${sqlQuery.key}`
				: `${sqlQuery.key}.keyword`
		] = sqlQuery.value;

		let obj = {};
		obj[`${keyForObj}`] = match;
		must.push({
			bool: {
				must_not: [obj],
			},
		});
	} else if (operator == 'is not between') {
		let splitByAnd = sqlQuery.value.split('AND');

		let rangeObj = {};
		rangeObj[`${sqlQuery.key}`] = {
			gte: sqlQuery.key.includes('sent_at', 'observed_at')
				? splitByAnd[0].toUpperCase().trim()
				: splitByAnd[0].trim(),

			lte: sqlQuery.key.includes('sent_at', 'observed_at')
				? splitByAnd[1].toUpperCase().trim()
				: splitByAnd[1].trim(),
		};
		must.push({
			bool: {
				must_not: [{ range: rangeObj }],
			},
		});
	} else if (operator == 'is between') {
		let splitByAnd = sqlQuery.value.split('AND');
		let rangeObj = {};
		rangeObj[`${sqlQuery.key}`] = {
			gte: sqlQuery.key.includes('sent_at', 'observed_at')
				? splitByAnd[0].toUpperCase().trim()
				: splitByAnd[0].trim(),

			lte: sqlQuery.key.includes('sent_at', 'observed_at')
				? splitByAnd[1].toUpperCase().trim()
				: splitByAnd[1].trim(),
		};
		must.push({
			range: rangeObj,
		});
	}
};
/* istanbul ignore next */
export const getElasticQuery = (groups, mapping) => {
	let should = [];
	let must1 = [];
	// const dateQuery = groups.find((val) => val.key == 'observed_at');
	// let rangeObj = {};
	// if (dateQuery) {
	// 	// add date filter in must1
	// 	let splitByAnd = dateQuery.value.split('AND');

	// 	rangeObj[`${dateQuery.key}`] = {
	// 		gte: dateQuery.key.includes('observed_at')
	// 			? splitByAnd[0].toUpperCase().trim()
	// 			: parseInt(splitByAnd[0].trim()),

	// 		lte: dateQuery.key.includes('observed_at')
	// 			? splitByAnd[1].toUpperCase().trim()
	// 			: parseInt(splitByAnd[1].trim()),
	// 	};
	// 	must1.push({
	// 		range: rangeObj,
	// 	});
	// }

	if (groups.length > 0) {
		if (groups.length > 1) {
			groups.map((orGroup) => {
				let must = [],
					must_not = [];
				orGroup.map((elem) => {
					return queryBuilder(elem, mapping, must, must_not);
				});

				if (must.length > 0) {
					should.push({
						bool: {
							must,
						},
					});
				}
			});
		} else {
			let must = [],
				must_not = [];

			groups[0].map((elem) => {
				return queryBuilder(elem, mapping, must, must_not);
			});

			must1.push({
				bool: {
					must,
				},
			});
		}
	}
	if (should.length > 0) {
		must1.push({ bool: { should } });
	}
	return { bool: { must: must1 } };
};
